import axios from 'axios'
//

export const addProfile = async (payload: any) => {
  const response = await axios.post(`/v0/api/user_profile`, {
    user_id: payload.user_id,
    profiles: payload.profiles,
  })
  return response.data
}
