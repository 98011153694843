export const HIP_USERS_KEYS_REQUEST = '@api/HIP_USERS_KEYS_REQUEST'
export const HIP_USERS_KEYS_SUCCESS = '@api/HIP_USERS_KEYS_SUCCESS'
export const HIP_USERS_KEYS_ERROR = '@api/HIP_USERS_KEYS_ERROR'

export const HIP_USER_DETAIL_REQUEST = '@api/HIP_USER_DETAIL_REQUEST'
export const HIP_USER_DETAIL_SUCCESS = '@api/HIP_USER_DETAIL_SUCCESS'
export const HIP_USER_DETAIL_ERROR = '@api/HIP_USER_DETAIL_ERROR'

export const HIP_USER_CREATE_REQUEST = '@api/HIP_USER_CREATE_REQUEST'
export const HIP_USER_CREATE_SUCCESS = '@api/HIP_USER_CREATE_SUCCESS'
export const HIP_USER_CREATE_ERROR = '@api/HIP_USER_CREATE_ERROR'

export const HIP_USER_UPDATE_REQUEST = '@api/HIP_USER_UPDATE_REQUEST'
export const HIP_USER_UPDATE_SUCCESS = '@api/HIP_USER_UPDATE_SUCCESS'
export const HIP_USER_UPDATE_ERROR = '@api/HIP_USER_UPDATE_ERROR'

export const HIP_USER_DELETE_REQUEST = '@api/HIP_USER_DELETE_REQUEST'
export const HIP_USER_DELETE_SUCCESS = '@api/HIP_USER_DELETE_SUCCESS'
export const HIP_USER_DELETE_ERROR = '@api/HIP_USER_DELETE_ERROR'

export const KEY_CREATE_REQUEST = '@api/KEY_CREATE_REQUEST'
export const KEY_CREATE_SUCCESS = '@api/KEY_CREATE_SUCCESS'
export const KEY_CREATE_ERROR = '@api/KEY_CREATE_ERROR'

export const KEY_UPDATE_REQUEST = '@api/KEY_UPDATE_REQUEST'
export const KEY_UPDATE_SUCCESS = '@api/KEY_UPDATE_SUCCESS'
export const KEY_UPDATE_ERROR = '@api/KEY_UPDATE_ERROR'

export const KEY_DELETE_REQUEST = '@api/KEY_DELETE_REQUEST'
export const KEY_DELETE_SUCCESS = '@api/KEY_DELETE_SUCCESS'
export const KEY_DELETE_ERROR = '@api/KEY_DELETE_ERROR'

export const HIP_USER_CLEAN_ERROR_REQUEST = '@api/HIP_USER_CLEAN_ERROR_REQUEST'
export const HIP_USER_CLEAN_SUCCESS_REQUEST = '@api/HIP_USER_CLEAN_SUCCESS_REQUEST'
