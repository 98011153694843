import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  rcusError,
  rcusRequest,
  rcusSuccess,
  rcuCreateSuccess,
  rcuCreateError,
  rcuCreateRequest,
  rcuDeleteSuccess,
  rcuDeleteError,
  rcuDeleteRequest,
  rcuUpdateSuccess,
  rcuUpdateRequest,
  rcuUpdateError,
  rcuDetailSuccess,
  rcuDetailRequest,
  rcuDetailError,
  availableRcusRequest,
  availableRcusSuccess,
  availableRcusError,
  rcuCleanErrorRequest,
  rcuCleanSuccessRequest,
} from '../../actions/rcus/actions'
import { deletedItem, Rcu, Room } from '../../types'
import { roomUpdateSuccess } from '../../actions/rooms/actions'
import { PaginatedResponse } from '../../../types'

interface RcusState {
  rcu: Rcu
  rcus: {
    page: number | null
    limit: number | null
    pages: number | null
    total: number | null
    items: Rcu[]
  }
  isLoading: boolean
  availablesRcus: Rcu[]
  success: string
  error: string
}

export const initialState = (): RcusState => ({
  rcu: {
    id: null,
    device_id: '',
    description: '',
    state: '',
  },
  rcus: {
    page: null,
    limit: null,
    pages: null,
    total: null,
    items: [],
  },
  isLoading: false,
  availablesRcus: [],
  success: '',
  error: '',
})

export const rcusReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(rcusRequest, (state) => {
      state.isLoading = true
    })
    .addCase(rcusSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const { page, limit, pages, total, items } = payload
      state.rcus = {
        page,
        limit,
        pages,
        total,
        items: items as Rcu[],
      }
      state.isLoading = false
    })
    .addCase(rcusError, (state, { payload }) => {
      console.error('rcusReducer - rcusError', payload)
      state.error = payload
      state.rcus = {
        page: null,
        limit: null,
        pages: null,
        total: null,
        items: [],
      }
      state.isLoading = false
    })
    .addCase(rcuDetailRequest, (state) => {
      state.isLoading = true
      state.rcu = {
        id: null,
        device_id: '',
        description: '',
        state: '',
      }
    })
    .addCase(rcuDetailSuccess, (state, { payload }: PayloadAction<Rcu>) => {
      const rcu = payload
      state.rcu = rcu
      state.isLoading = false
    })
    .addCase(rcuDetailError, (state, { payload }) => {
      console.error('rcusReducer - rcuDetailError', payload)
      state.error = payload
      state.rcu = {
        id: null,
        device_id: '',
        description: '',
        state: '',
      }
      state.isLoading = false
    })
    .addCase(availableRcusRequest, (state) => {
      state.availablesRcus = []
    })
    .addCase(availableRcusSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const availableRcus = payload.items as Rcu[]
      state.availablesRcus = availableRcus
    })
    .addCase(availableRcusError, (state, { payload }) => {
      console.error('rcusReducer - availableRcusError', payload)
      state.error = payload
      state.availablesRcus = []
    })
    .addCase(rcuCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(rcuCreateSuccess, (state, { payload }: PayloadAction<Rcu>) => {
      const rcuCreated = payload
      state.rcus.items.unshift(rcuCreated)
      state.success = 'add_success'
      state.isLoading = false
    })
    .addCase(rcuCreateError, (state, { payload }) => {
      console.error('rcusReducer - rcuCreateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(rcuUpdateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(rcuUpdateSuccess, (state, { payload }: PayloadAction<Rcu>) => {
      const currentRcu = state.rcus
      const updatedRcu = {
        ...currentRcu,
        items: currentRcu.items.map((rcu) => (rcu.id === payload.id ? payload : rcu)),
      }
      state.rcus = updatedRcu
      state.rcu = payload
      state.success = 'update_success'
      state.isLoading = false
    })
    .addCase(rcuUpdateError, (state, { payload }) => {
      console.error('rcusReducer - rcuUpdateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(roomUpdateSuccess, (state, { payload }: PayloadAction<Room>) => {
      const existingRcus = state.availablesRcus.filter((existingRcu) => {
        payload.rcus.some((rcu: Rcu) => rcu.id === existingRcu.id)
      })
      state.availablesRcus = existingRcus
    })
    .addCase(rcuDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(rcuDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
      const currentItems = state.rcus
      const updatedItems = {
        ...currentItems,
        total: currentItems.total ? currentItems.total - 1 : 0,
        items: currentItems.items.filter((rcu) => rcu.id !== Number(payload.id)),
      }
      state.rcus = updatedItems
      state.success = 'delete_success'
      state.isLoading = false
    })
    .addCase(rcuDeleteError, (state, { payload }) => {
      console.error('rcusReducer - rcuDeleteError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(rcuCleanSuccessRequest, (state) => {
      state.success = ''
    })
    .addCase(rcuCleanErrorRequest, (state) => {
      state.error = ''
    })
})
