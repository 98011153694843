import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/material'
import { TDeviceSize, TModeView } from '../../types'
import { ButtonAtr, MessageNote, HipIconType, RoomFeedback } from '../types'
import HipButton from '../components/HipButton'
import { getButtonAttributesAndMessage } from './BoardAuxiliar'
import { getStyle } from '../../components/DevicesViewStyle'
import ButtonText from './ButtonText'
import { MenuItemActions } from '../../dashboard/components/ContextMenu'
import { useDispatch } from 'react-redux'
import { mqttRequest } from '../../common/store/actions/mqtt/actions'

interface IProps {
  room: RoomFeedback
  deviceSize: TDeviceSize
  onlyEvents: boolean
  viewMode: TModeView
  rightMenu: MenuItemActions[]
  isProfile: number
}

const RoomButton = ({ room, deviceSize, onlyEvents, viewMode, isProfile, ...props }: IProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const style = getStyle(viewMode)

  const { feedback } = room

  const defaultButton: ButtonAtr = {
    image: 'ERROR',
    fill: '#354652',
  }

  const defaultMessage: MessageNote = {
    message: null,
  }

  const { attributes: buttonAttributes, message: buttonMessage } = feedback
    ? getButtonAttributesAndMessage(feedback)
    : { attributes: defaultButton, message: defaultMessage }

  const bedNumber = feedback?.params?.bed_no || ''
  const accessKey = feedback?.params?.card || ''
  const user_name = feedback?.params?.user_name || ''

  const roomInfo = [
    {
      attributes: buttonAttributes,
      name: room.name,
      bed: bedNumber ? `Bed ${bedNumber}` : undefined,
      user_name: user_name ? `${user_name}` : undefined,
      accessKey: accessKey ? `Id ${accessKey}` : undefined,
    },
  ]

  useEffect(() => {
    if (room && buttonMessage.message) {
      enqueueSnackbar(`${room.name} ${bedNumber} ${buttonMessage.message}`, {
        variant: 'warning',
        autoHideDuration: 900000,
        preventDuplicate: true,
      })
    }
  }, [buttonMessage.message])

  const handleClick = (room_id: number) => {
    switch (isProfile) {
      case 2:
      case 3:
        dispatch(
          mqttRequest({
            topic: 'server_event/jnew/rcu',
            payload: `{"new":"BAssign", "params": {"room":"${room_id}"}}`,
          }),
        )
        break
      default:
        return
    }
  }

  return (
    <div>
      <Box display="flex" flexWrap="wrap" m={4}>
        {onlyEvents ? (
          roomInfo[0].attributes.image !== 'OK' && (
            <Box key={room.id} m={2} sx={style.deviceStyle}>
              <HipButton
                viewMode="large-icon"
                image={roomInfo[0].attributes.image as HipIconType}
                size={deviceSize}
                fill={roomInfo[0].attributes.fill}
                rightMenu={props.rightMenu}
                onClick={() => handleClick(room.id)}
              />
              <ButtonText
                textStyles={style.textStyles}
                id={room.id}
                name={room.name}
                bed={roomInfo[0].bed}
                accessKey={room?.feedback?.new !== 'EoSC' ? roomInfo[0].accessKey : undefined}
                user_name={roomInfo[0].user_name}
              />
            </Box>
          )
        ) : (
          <Box key={room.id} m={2} sx={style.deviceStyle}>
            <HipButton
              viewMode="large-icon"
              image={roomInfo[0].attributes.image as HipIconType}
              size={deviceSize}
              fill={roomInfo[0].attributes.fill}
              rightMenu={props.rightMenu}
              onClick={() => handleClick(room.id)}
            />
            <ButtonText
              textStyles={style.textStyles}
              id={room.id}
              name={room.name}
              bed={roomInfo[0].bed}
              accessKey={room?.feedback?.new !== 'EoSC' ? roomInfo[0].accessKey : undefined}
              user_name={roomInfo[0].user_name}
            />
          </Box>
        )}
      </Box>
    </div>
  )
}

export default RoomButton
