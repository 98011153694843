import { useDispatch } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import { useSSE } from 'react-hooks-sse'
import { useTranslation } from 'react-i18next'
import { TModeView } from '../../types'
import { RoomFeedback } from '../types'
import { Box, Grid, MenuItem, Select } from '@mui/material'
import { getStyle } from '../../components/DevicesViewStyle'
import { mqttRequest } from '../../common/store/actions/mqtt/actions'
import DraggableButton from './DraggableButton'
import { DeviceSortList, IDeviceOrdering } from '../../devices/DeviceSortList'

interface IProps {
  isDesktopMode: boolean
  onlyEvents: boolean
  isProfile: number
  rooms: number[]
}

const RoomsViews = ({ isDesktopMode, onlyEvents, isProfile, rooms }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Hanldes events by profile
  const roomsHealthEvents = useSSE<RoomFeedback[]>('rooms_health', [])
  const roomsCleaningEvents = useSSE<RoomFeedback[]>('rooms_cleaning', [])
  const roomsServiceEvents = useSSE<RoomFeedback[]>('rooms_service', [])
  const roomsAdmissionEvents = useSSE<RoomFeedback[]>('rooms_admission', [])

  const roomsEventsByProfile = (profile: number) => {
    switch (profile) {
      case 1:
      case 2:
      case 3:
        return roomsHealthEvents
      case 4:
      case 7:
        return roomsAdmissionEvents
      case 5:
      case 6:
        return roomsCleaningEvents
      case 8:
        return roomsServiceEvents
      default:
        return roomsHealthEvents
    }
  }

  const [orderedRooms, setOrderedRooms] = useState<RoomFeedback[]>([])

  const filteredAndSortedRooms = useMemo(() => {
    const roomsEvents = roomsEventsByProfile(isProfile)
    const filteredRooms = roomsEvents?.filter((room) => rooms?.includes(room.id))
    const sortedRooms = filteredRooms.sort((a, b) => a.orderId - b.orderId)
    return sortedRooms
  }, [
    isProfile,
    roomsHealthEvents,
    roomsCleaningEvents,
    roomsServiceEvents,
    roomsAdmissionEvents,
    rooms,
  ])

  // Handles icon size //
  const [viewMode, setViewMode] = useState<TModeView>(isDesktopMode ? 'large-icon' : 'medium-icon')
  const deviceSize = useMemo(() => {
    switch (viewMode) {
      case 'large-icon':
        return isDesktopMode ? 120 : 90
      case 'medium-icon':
        return isDesktopMode ? 100 : 72
      case 'small-icon':
        return isDesktopMode ? 72 : 64
      case 'list':
        return 32
      default:
        return 120
    }
  }, [viewMode, isDesktopMode])

  const style = getStyle(viewMode)

  // Handles alphabetical order
  const [deviceOrdering, setDeviceOrdering] = useState<IDeviceOrdering>({
    orderBy: 'desc',
    ascendent: true,
  })

  useEffect(() => {
    const list = [...filteredAndSortedRooms]
    const direction = deviceOrdering.ascendent ? 1 : -1
    switch (deviceOrdering.orderBy) {
      case 'desc':
        list.sort((a, b) => direction * (a.name > b.name ? 1 : -1))
        break
      case 'id':
        list.sort((a, b) => direction * (a.id > b.id ? 1 : -1))
        break
      default:
        break
    }
    setOrderedRooms(list)
  }, [deviceOrdering, filteredAndSortedRooms])

  // Hanldes roomOrder by drag & drop feature
  const reorderRooms = (draggedIndex: number, targetIndex: number) => {
    const updatedRooms = [...orderedRooms]
    const [removed] = updatedRooms.splice(draggedIndex, 1)
    updatedRooms.splice(targetIndex, 0, removed)
    setOrderedRooms(updatedRooms)
  }

  //Right menu
  const getRightMenu = (profile: number, room_id: number) => {
    switch (profile) {
      case 1:
      case 2:
      case 3:
        return [
          {
            label: t('override_call'),
            onClick: () => {
              dispatch(
                mqttRequest({
                  topic: 'server_event/jnew/rcu',
                  payload: `{"new":"EoSX","room":"${room_id}"}`,
                }),
              )
            },
          },
        ]
      case 7:
        return [
          {
            label: t('occupied_room'),
            onClick: () => {
              dispatch(
                mqttRequest({
                  topic: 'server_event/jnew/rcu',
                  payload: `{"new":"EoSRR","room":"${room_id}"}`,
                }),
              )
            },
          },
        ]
      default:
        return []
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box border={'1px solid grey'} borderRadius="5px">
          <Box>
            <DeviceSortList
              ordering={deviceOrdering}
              onOrderChange={(ev) => setDeviceOrdering(ev.ordering)}
              allowById={false}
            />
            <Select
              label="View"
              defaultValue={viewMode}
              size="small"
              onChange={(event) => setViewMode(event.target.value as TModeView)}
              data-e2e="viewModeSelect"
            >
              <MenuItem value="large-icon">Large Icon</MenuItem>
              <MenuItem value="medium-icon">Medium Icon</MenuItem>
              <MenuItem value="small-icon">Small Icon</MenuItem>
              <MenuItem value="list">List</MenuItem>
            </Select>
          </Box>
          <Box
            data-e2e="device-list"
            display="grid"
            gridTemplateRows="repeat(auto-fill, minmax(120px, 1fr))"
            gap="10px"
            justifyContent="center"
            width="100%"
            mt={'2%'}
          >
            <Box sx={isDesktopMode ? style.layoutStyle : style.mobileStyle} data-e2e="room-list">
              {orderedRooms.map((room, index) => (
                <DraggableButton
                  key={room.id}
                  isProfile={isProfile}
                  room={room}
                  deviceSize={deviceSize}
                  onlyEvents={onlyEvents}
                  viewMode={viewMode}
                  rightMenu={getRightMenu(isProfile, room.id)}
                  index={index}
                  setOrderedRooms={setOrderedRooms}
                  reorderRooms={reorderRooms}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default RoomsViews
