export const LOGIN_REQUEST = '@api/LOGIN_REQUEST'
export const LOGIN_SUCCESS = '@api/LOGIN_SUCCESS'
export const LOGIN_ERROR = '@api/LOGIN_ERROR'

export const USER_INFO_REQUEST = '@api/USER_INFO_REQUEST'
export const USER_INFO_SUCCESS = '@api/USER_INFO_SUCCESS'
export const USER_INFO_ERROR = '@api/USER_INFO_ERROR'
export const CLEAN_LOGIN_STATE = '@api/CLEAN_LOGIN_ERROR'

export const USER_NOTIFICATION_TOKEN_REQUEST = '@api/USER_NOTIFICATION_TOKEN_REQUEST'
export const USER_PUSH_SUBSCRIPTION_REQUEST = '@api/USER_PUSH_SUBSCRIPTION_REQUEST'

export const USER_CLEAN_ERROR_REQUEST = '@api/USER_CLEAN_ERROR_REQUEST'
export const USER_CLEAN_SUCCESS_REQUEST = '@api/USER_CLEAN_SUCCESS_REQUEST'
