import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { UserType } from '../../../../types'
import { usersError, usersSuccess } from '../../actions/users/actions'
import { usersGetter } from '../../../api/users/usersGetter'

export function* sagaGetUsers() {
  try {
    const response: UserType[] = yield call(usersGetter)
    yield put(usersSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(usersError(error.response.data as unknown))
    } else {
      yield put(usersError(error))
      throw error
    }
  }
}
