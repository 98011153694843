import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { settingsDetailsError, settingsDetailsSuccess } from '../../actions/settings/actions'
import { Setting } from '../../../../types'
import { settingsDetails } from '../../../api/settings/settingsDetails'
import { Action } from '../root'

export function* sagaGetDetails({ payload }: Action<number>) {
  try {
    const response: Setting = yield call(settingsDetails, payload)
    yield put(settingsDetailsSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(settingsDetailsError(error.response.data as unknown))
    } else {
      yield put(settingsDetailsError(error))
      throw error
    }
  }
}
