import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Iprops {
  label: string
  value: string | number
  'data-e2e'?: string
}

const DetailItem = ({ label, value, 'data-e2e': dataE2E }: Iprops) => {
  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={6}>
        <Typography data-e2e={`${dataE2E}-${label}`}>
          {t(label)}: {value}
        </Typography>
      </Grid>
    </>
  )
}

export default DetailItem
