import { useState } from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FileDownload, ExpandLess, ExpandMore } from '@mui/icons-material'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'
import { TTradFunc } from '../../types'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../../common/store/selectors/user'

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(15),
  },
  icon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  },
  tab: {
    marginLeft: theme.spacing(2),
  },
}))

interface IProps {
  t: TTradFunc
}

const ApplicationLogs = ({ t }: IProps) => {
  const { product_name } = useSelector(getUserInfo)
  const { hasPrivilege } = usePrivilege()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    hasPrivilege(Privileges.VIEW_APP_LOGS) && (
      <div>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <FileDownload />
          </ListItemIcon>
          <ListItemText primary={t('application_logs')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              className={classes.nested}
              data-e2e="navbar-logs"
              component="a"
              href={`/v2/api/application-log/logs/csv`}
            >
              <ListItemIcon className={classes.icon}>
                <SimCardDownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('application_logs_logs')} className={classes.tab} />
            </ListItemButton>
            {product_name == 'HELP_IP' && (
              <ListItemButton
                className={classes.nested}
                data-e2e="navbar-reports"
                component="a"
                href={`/v2/api/application-log/reports/csv`}
              >
                <ListItemIcon className={classes.icon}>
                  <SimCardDownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t('application_logs_reports')} className={classes.tab} />
              </ListItemButton>
            )}
          </List>
        </Collapse>
      </div>
    )
  )
}

export default ApplicationLogs
