import axios from 'axios'

import { Setting } from '../../../types'

export const updateSettings = async (payload: Setting) => {
  const response = await axios.patch(`/v2/api/settings/${payload.id}`, {
    ...payload,
  })
  return response.data
}
