import { Route } from 'react-router-dom'
import RoomsView from './rooms/RoomsView'
import React from 'react'
import RoomDetail from './rooms/RoomDetail'
import RcusView from './rcus/RcusView'
import RcuDetail from './rcus/RcusDetail'
import HipUserView from './hipUsers/HipUsersView'
import HipUserDetail from './hipUsers/HipUserDetail'
import SettingsPage from '../settings/Settings'
import SettingsForm from '../settings/SettingsForm'
import { ZoneManager } from '../zones/ZoneManager'
import { ZoneForm } from '../zones/ZoneForm'
import { Profiles } from '../profiles/profilesManager'
import ProfilesForm2 from '../profiles/ProfilesForm2'

const HelpIPRoutes = (
  <React.Fragment>
    <Route path="rooms" element={<RoomsView />} />
    <Route path="rooms/:id" element={<RoomDetail />} />
    <Route path="rcus" element={<RcusView />} />
    <Route path="rcus/:id" element={<RcuDetail />} />
    <Route path="nurses" element={<HipUserView userType="nurses" />} />
    <Route path="nurses/:id" element={<HipUserDetail userType="nurses" />} />
    <Route path="maintenance" element={<HipUserView userType="maintenance" />} />
    <Route path="maintenance/:id" element={<HipUserDetail userType="maintenance" />} />
    <Route path="cleaning" element={<HipUserView userType="cleaning" />} />
    <Route path="cleaning/:id" element={<HipUserDetail userType="cleaning" />} />
    <Route path="roomReady" element={<HipUserView userType="roomReady" />} />
    <Route path="roomReady/:id" element={<HipUserDetail userType="roomReady" />} />
    <Route path="roomService" element={<HipUserView userType="roomService" />} />
    <Route path="roomService/:id" element={<HipUserDetail userType="roomService" />} />
    <Route path="admission" element={<HipUserView userType="admission" />} />
    <Route path="admission/:id" element={<HipUserDetail userType="admission" />} />
    <Route path="groups" element={<ZoneManager />} />
    <Route path="groups/:id" element={<ZoneForm />} />
    <Route path="profiles" element={<Profiles />} />
    <Route path="profiles/:id" element={<ProfilesForm2 />} />
    <Route path="rcu_settings" element={<SettingsPage scope="rcu" />} />
    <Route path="settings/add_rcu_settings" element={<SettingsForm scope="rcu" />} />
  </React.Fragment>
)

export default HelpIPRoutes
