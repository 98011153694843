import { call, put } from '@redux-saga/core/effects'
import {
  INotificationTokenRequest,
  IPushSubscriptionRequest,
  LoginUserInfo,
  LoginInfoRequest,
} from '../../api/types'
import axios from 'axios'
import {
  getLoginInfo,
  postNotificationToken,
  postPushSubscription,
} from '../../api/login/loginInfo'
import { userInfoError, userInfoSuccess } from '../actions/user/actions'
import { Action } from './root'

export function* userInfo({ payload }: Action<LoginInfoRequest>) {
  try {
    const response: LoginUserInfo = yield call(getLoginInfo, payload.username)
    yield put(userInfoSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(userInfoError(error.response.data as unknown))
      console.log('Error', error)
    } else {
      yield put(userInfoError(error))
      console.log('Error2', error)
      throw error
    }
  }
}

export function* userNotificationTokenRequest({ payload }: Action<INotificationTokenRequest>) {
  yield call(postNotificationToken, payload)
}

export function* userPushSubscriptionRequest({ payload }: Action<IPushSubscriptionRequest>) {
  yield call(postPushSubscription, payload)
}
