import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { UserType, NewUserData } from '../../../../types'
import { userCreate } from '../../../api/users/userCreate'
import { userCreateError, userCreateSuccess } from '../../actions/users/actions'
import { addProfile } from '../../../../hip/api/userAddProfile'

export function* sagaUserCreate({ payload }: Action<NewUserData>) {
  const { data, profiles } = payload
  try {
    const response: UserType = yield call(userCreate, data)
    const profileData = {
      user_id: response.id,
      profiles: profiles,
    }
    yield call(addProfile, profileData)
    yield put(userCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(userCreateError(error.response.data as unknown))
    } else {
      yield put(userCreateError(error))
      throw error
    }
  }
}
