import axios from 'axios'

import { KeyFormValues } from '../types'

export const keyCreate = async (payload: KeyFormValues) => {
  const response = await axios.post(`/v2/api/access-keys`, {
    key: payload.key,
    user_id: payload.user_id,
  })
  return response.data
}
