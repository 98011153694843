import { Paper, Theme, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSSE } from 'react-hooks-sse'
import { useSelector } from 'react-redux'
import { groupsStates } from '../../common/store/selectors/groups'
import { DeviceEvent } from '../../types'

const styles = {
  zonePaper: (theme: Theme) => ({
    backgroundColor: theme.palette.success.light,
    color: theme.palette.white.main,
    height: '100%',
    padding: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

const ReadyZone = () => {
  const [zoneName, setZoneName] = useState<null | string>(null)
  const zonesState = useSelector(groupsStates)
  const allDevices = useSSE<DeviceEvent[]>('devices', [])
  const devices = allDevices.filter((device) => device.type !== 'rcu')
  const inStop = !!devices.find((device) => device.state === 'READY')

  useEffect(() => {
    if (inStop) {
      const activeDevices = devices
        .filter((device) => device.state === 'READY' && device.type !== 'microphone')
        .map((device) => device.device_id)
      console.log('active', activeDevices)

      const selectedZone = zonesState.groups.find((zone) => {
        if (!zone || !zone.devices) {
          return false
        }
        const zoneDevices = zone.devices.map((device) => device.device_id)
        console.log(zone.name, zoneDevices)

        const array2Sorted = zoneDevices.slice().sort()
        if (
          activeDevices.length === zoneDevices.length &&
          activeDevices
            .slice()
            .sort()
            .every(function (value, index) {
              return value === array2Sorted[index]
            })
        ) {
          return zone
        }
      })
      if (selectedZone) {
        setZoneName(selectedZone.name)
      } else {
        setZoneName(null)
      }
    } else {
      setZoneName(null)
    }
  }, [allDevices])

  return (
    <>
      {zoneName && (
        <Paper elevation={4} sx={styles.zonePaper}>
          <Typography variant="h6">{zoneName}</Typography>
        </Paper>
      )}
    </>
  )
}

export default ReadyZone
