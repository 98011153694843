import { createAction } from '@reduxjs/toolkit'
import {
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_ERROR,
  SETTINGS_CREATE_REQUEST,
  SETTINGS_CREATE_SUCCESS,
  SETTINGS_CREATE_ERROR,
  SETTINGS_UPDATE_REQUEST,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_ERROR,
  SETTINGS_DELETE_REQUEST,
  SETTINGS_DELETE_SUCCESS,
  SETTINGS_DELETE_ERROR,
  SETTINGS_CLEAN_REQUEST,
  SETTINGS_CLEAN_SUCCESS_REQUEST,
  SETTINGS_CLEAN_ERROR_REQUEST,
  SETTINGS_DETAILS_REQUEST,
  SETTINGS_DETAILS_SUCCESS,
  SETTINGS_DETAILS_ERROR,
  IS_LOADING_MODAL,
} from './actionTypes'
import { PageSet, deletedItem } from '../../../../hip/types'
import { NewSetting, PaginatedResponse, Setting } from '../../../../types'

export const settingsRequest = createAction<PageSet>(SETTINGS_REQUEST)
export const settingsSuccess = createAction<PaginatedResponse>(SETTINGS_SUCCESS)
export const settingsError = createAction<any>(SETTINGS_ERROR)

export const settingsCreateRequest = createAction<NewSetting>(SETTINGS_CREATE_REQUEST)
export const settingsCreateSuccess = createAction<Setting>(SETTINGS_CREATE_SUCCESS)
export const settingsCreateError = createAction<any>(SETTINGS_CREATE_ERROR)

export const settingsDetailsRequest = createAction<number>(SETTINGS_DETAILS_REQUEST)
export const settingsDetailsSuccess = createAction<Setting>(SETTINGS_DETAILS_SUCCESS)
export const settingsDetailsError = createAction<any>(SETTINGS_DETAILS_ERROR)

export const setIsLoadingModal = createAction<boolean>(IS_LOADING_MODAL)

export const settingsUpdateRequest = createAction<Setting>(SETTINGS_UPDATE_REQUEST)
export const settingsUpdateSuccess = createAction<Setting>(SETTINGS_UPDATE_SUCCESS)
export const settingsUpdateError = createAction<any>(SETTINGS_UPDATE_ERROR)

export const settingsDeleteRequest = createAction<number>(SETTINGS_DELETE_REQUEST)
export const settingsDeleteSuccess = createAction<deletedItem>(SETTINGS_DELETE_SUCCESS)
export const settingsDeleteError = createAction<any>(SETTINGS_DELETE_ERROR)

export const settingsCleanRequest = createAction<number>(SETTINGS_CLEAN_REQUEST)
export const settingsCleanSuccessRequest = createAction(SETTINGS_CLEAN_SUCCESS_REQUEST)
export const settingsCleanErrorRequest = createAction(SETTINGS_CLEAN_ERROR_REQUEST)
