import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { availableRcusError, availableRcusSuccess } from '../../../actions/rcus/actions'
import { getAvailableDevices } from '../../../api/availableDevices'
import { PaginatedResponse } from '../../../../types'

export function* sagaGetAvailableRcus() {
  try {
    const response: PaginatedResponse = yield call(getAvailableDevices, 'rcus')
    yield put(availableRcusSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(availableRcusError(error.response.data as unknown))
    } else {
      yield put(availableRcusError(error))
      throw error
    }
  }
}
