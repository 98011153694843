import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { CommonContent } from '../components/CommonContent'
import { Delete, DevicesOther, Edit } from '@mui/icons-material'
import DevicesIcon from '@mui/icons-material/Devices'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { licenseRequest } from '../common/store/actions/license/action'
import {
  deviceCleanErrorRequest,
  deviceCleanRequest,
  deviceCleanSuccessRequest,
  deviceDeleteRequest,
  ipdevicesRequest,
} from '../common/store/actions/devices/actions'
import { getLicense } from '../common/store/selectors/license'
import { devicesState } from '../common/store/selectors/devices'
import { IpDevice } from '../types'

export const Devices = () => {
  const { hasPrivilege } = usePrivilege()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { ipDevices, success, error } = useSelector(devicesState)
  const devices = ipDevices.items.filter((device) => device.type !== 'rcu') as IpDevice[]
  const [open, setOpen] = useState(false)
  const [deviceToDelete, setDeviceToDelete] = useState<number>(null)
  const licenseState = useSelector(getLicense)

  useEffect(() => {
    dispatch(ipdevicesRequest({ limit: '200', page: '1' }))
    dispatch(licenseRequest())
  }, [dispatch])

  useEffect(() => {
    if (ipDevices.items.length > 0) {
      console.log('rendering', ipDevices)
    }
  }, [ipDevices])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(deviceCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(deviceCleanErrorRequest())
    }
  }, [success, error])

  const handleClickAdd = () => {
    dispatch(deviceCleanRequest())
    navigate('/devices/add')
  }

  const handleClickEdit = (id: number) => {
    dispatch(deviceCleanRequest())
    navigate('/devices/' + id)
  }

  const handleClick = (device: IpDevice) => {
    setDeviceToDelete(device.id)
    setOpen(true)
  }

  const handleConfirmDelete = async () => {
    dispatch(deviceDeleteRequest(deviceToDelete))
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <CommonContent title={t('devices')} titleIcon={<DevicesIcon style={{ color: 'white' }} />}>
      {hasPrivilege(Privileges.ADD_DEVICE) && (
        <Box mb={4}>
          <Button
            data-e2e="devices-btn-add"
            onClick={handleClickAdd}
            variant="contained"
            color="success"
            startIcon={<DevicesOther />}
            disabled={
              !licenseState.devices ||
              (licenseState.devices && devices.length >= licenseState.devices)
            }
          >
            {t('devices_add')}
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Divider />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"> {t('mac')}</TableCell>
              <TableCell align="center">{t('desc')}</TableCell>
              <TableCell align="center">{t('state')}</TableCell>
              <TableCell align="center">{t('type')}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {devices.map((device) => (
              <TableRow key={device.device_id} data-e2e={'devices-row-' + String(device.device_id)}>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  data-e2e={'devices-row-cell-id'}
                >
                  {device.device_id}
                </TableCell>
                <TableCell align="center" data-e2e={'devices-row-cell-description'}>
                  {device.description}
                </TableCell>
                <TableCell align="center" data-e2e={'devices-row-cell-state'}>
                  {device.state}
                </TableCell>
                <TableCell align="center" data-e2e={'devices-row-cell-name'}>
                  {device.type}
                </TableCell>

                <TableCell align="center" data-e2e={'devices-row-cell-actions2'}>
                  <Grid>
                    {hasPrivilege(Privileges.EDIT_DEVICE) && (
                      <Button
                        onClick={() => handleClickEdit(device.id)}
                        data-e2e={'devices-row-cell-actions-edit'}
                      >
                        <Edit color="primary" />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center" data-e2e={'devices-row-cell-actions1'}>
                  <Grid>
                    {hasPrivilege(Privileges.DELETE_DEVICE) && (
                      <Button
                        variant="text"
                        onClick={() => handleClick(device)}
                        data-e2e={'devices-row-cell-actions-delete'}
                      >
                        <Delete color="error" />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
                {/* <TableCell align="center" data-e2e={'devices-row-cell-actions3'}>
                  <Grid>
                    {hasPrivilege(Privileges.VIEW_DEVICE) && (
                      <Button
                        variant="text"
                        component={Link}
                        to={'/devices/info-' + device.id}
                        data-e2e={'devices-row-cell-actions-info'}
                      >
                        <Info style={{ color: 'blue' }} />
                      </Button>
                    )}
                  </Grid>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e="devices-add-dlg-delete"
      >
        <DialogTitle id="responsive-dialog-title">{t('devices_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('device_delete_ask')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDelete()}
            variant="outlined"
            color="secondary"
            data-e2e="devices-add-dlg-delete-btn-delete"
          >
            {t('delete')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
            data-e2e="devices-add-dlg-delete-btn-cancel"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </CommonContent>
  )
}
