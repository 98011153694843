import { Privileges } from '../enum'
import { Delete, Edit, Settings } from '@mui/icons-material'

import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Divider,
  Paper,
  Box,
  TableBody,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material'

import {
  settingsRequest,
  settingsCleanRequest,
  settingsDeleteRequest,
  settingsCleanSuccessRequest,
  settingsCleanErrorRequest,
} from '../common/store/actions/settings/actions'
import { CommonContent } from '../components/CommonContent'
import usePrivilege from '../hooks/usePrivilege'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { settingsStates } from '../common/store/selectors/settings'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

interface IProps {
  scope: string
}

const SettingsPage = ({ scope }: IProps) => {
  const { hasPrivilege } = usePrivilege()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [settingDelete, setSettingDelete] = useState<number>(null)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { settings, success, error, isLoading } = useSelector(settingsStates)

  useEffect(() => {
    dispatch(settingsRequest({ limit: '200', page: '1' }))
  }, [dispatch])

  useEffect(() => {
    if (settings.items.length > 0) {
      console.log('rendering', settings)
    }
  }, [settings])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(settingsCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(settingsCleanErrorRequest())
    }
  }, [success, error])

  const sortedSettings = useMemo(() => {
    return settings.items
      .filter((setting) => setting.scope === scope)
      .sort((a, b) => 1 * (a.key > b.key ? 1 : -1))
  }, [settings.items, scope])

  const handleClickAdd = () => {
    dispatch(settingsCleanRequest())
    navigate(`/settings/add_${scope}_settings`)
  }

  const handleClickEdit = (id: number) => {
    dispatch(settingsCleanRequest())
    navigate('/settings/' + id)
  }

  const handleClick = (setting: number) => {
    setSettingDelete(setting)
    setOpen(true)
  }

  const handleConfirmDelete = () => {
    dispatch(settingsDeleteRequest(settingDelete))
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <CommonContent
      title={t(`${scope}_settings`)}
      titleIcon={<Settings style={{ color: 'white' }} />}
    >
      {hasPrivilege(Privileges.ADD_SETTINGS) && (
        <Box mb={4}>
          <Button
            data-e2e="settings-btn-add"
            onClick={handleClickAdd}
            variant="contained"
            color="success"
            startIcon={<Settings />}
          >
            {t('add_setting')}
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Divider />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"> {'Key'}</TableCell>
              <TableCell align="center">{'Type'}</TableCell>
              <TableCell align="center">{'Value'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              sortedSettings.map((setting) => (
                <TableRow key={setting.id} data-e2e={'settings-row-' + String(setting.id)}>
                  <TableCell align="center" data-e2e={'settings-row-cell-key'}>
                    {setting.key}
                  </TableCell>
                  <TableCell align="center" data-e2e={'settings-row-cell-type'}>
                    {setting.type}
                  </TableCell>
                  <TableCell align="center" data-e2e={'settings-row-cell-value'}>
                    {setting.value}
                  </TableCell>
                  <TableCell align="center" data-e2e={'settings-row-cell-action2'}>
                    <Grid>
                      {hasPrivilege(Privileges.EDIT_SETTINGS) && (
                        <Box mb={4}>
                          <Button
                            onClick={() => handleClickEdit(setting.id)}
                            data-e2e={'settings-row-cell-action2'}
                          >
                            <Edit color="primary" />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell align="center" data-e2e={'settings-row-cell-action1'}>
                    <Grid>
                      {hasPrivilege(Privileges.DELETE_SETTINGS) && (
                        <Box mb={4}>
                          <Button
                            onClick={() => handleClick(setting.id)}
                            variant="text"
                            data-e2e={'settings-row-cell-action1'}
                          >
                            <Delete color="error" />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e="setting-add-dlg-delete"
      >
        <DialogTitle id="responsive-dialog-title">{t('setting_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('setting_delete_ask')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDelete()}
            variant="outlined"
            color="secondary"
            data-e2e="setting-add-dlg-delete-btn-delete"
          >
            {t('delete')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
            data-e2e="setting-add-dlg-delete-btn-cancel"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </CommonContent>
  )
}

export default SettingsPage
