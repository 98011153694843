import { TModeView } from '../types'

export const getStyle = (viewMode: TModeView) => {
  switch (viewMode) {
    case 'medium-icon':
      return {
        descStyle: {
          height: '2.6em',
          lineClamp: '2',
          lineHeight: '1.3em',
          overflow: 'hidden',
          wordBreak: 'break-word',
          width: '120px',
        },
        deviceStyle: {},
        layoutStyle: { display: 'flex', flexWrap: 'wrap' },
        mobileStyle: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' },
        textStyles: {},
      }

    case 'small-icon':
      return {
        descStyle: {
          height: '3em',
          lineClamp: '2',
          overflow: 'hidden',
          wordBreak: 'break-all',
          width: '160px',
        },
        deviceStyle: { display: 'flex', alignItems: 'center' },
        layoutStyle: { display: 'flex', flexWrap: 'wrap' },
        mobileStyle: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' },
        textStyles: { marginLeft: '0.25em' },
      }

    case 'list':
      return {
        descStyle: {
          height: '1.5em',
          lineClamp: '1',
          overflow: 'hidden',
          wordBreak: 'break-word',
        },
        deviceStyle: { display: 'flex', alignItems: 'center' },
        layoutStyle: { display: 'flex', flexDirection: 'column' },
        mobileStyle: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' },
        textStyles: { marginLeft: '1em' },
      }

    default:
      return {
        descStyle: {
          height: '3em',
          lineClamp: '2',
          overflow: 'hidden',
          wordBreak: 'break-word',
          width: '160px',
        },
        deviceStyle: {},
        layoutStyle: { display: 'flex', flexWrap: 'wrap' }, // display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))'
        mobileStyle: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' },
        textStyles: {},
      }
  }
}
