import axios from 'axios'

import { INotificationTokenRequest, IPushSubscriptionRequest } from '../types'

export const getLoginInfo = async (username: string) => {
  const response = await axios.get(`/v2/api/users/${username}`, {
    withCredentials: true,
  })
  return response.data
}

export const postNotificationToken = async (payload: INotificationTokenRequest) => {
  const response = await axios.post(
    `/v2/api/users/notification-token`,
    {
      token: payload.token,
    },
    {
      auth: {
        username: payload.user,
        password: payload.password,
      },
    },
  )
  return response.data
}

export const postPushSubscription = async (payload: IPushSubscriptionRequest) => {
  const response = await axios.post(`/v2/api/users/push-subscription`, payload.pushSubscription, {
    withCredentials: true,
  })
  return response.data
}
