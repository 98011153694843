import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { User_Key } from '../../../types'
import { NewUserData, UserDetail } from '../../../../types'
import { userUpdate } from '../../../api/userUpdate'
import { hip_userUpdateError, hip_userUpdateSuccess } from '../../../actions/hipUsers/actions'
import { hipUserDetail } from '../../../api/hipUserDetail'
import { addProfile } from '../../../api/userAddProfile'

export function* sagaHipUserUpdate({ payload }: Action<NewUserData>) {
  try {
    const response: UserDetail = yield call(userUpdate, payload)
    const { profiles } = payload
    if (profiles.length) {
      const profileData = {
        user_id: response.id,
        profiles: profiles,
      }
      yield call(addProfile, profileData)
    }
    const response2: User_Key = yield call(hipUserDetail, response.id.toString())
    yield put(hip_userUpdateSuccess(response2))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(hip_userUpdateError(error.response.data as unknown))
    } else {
      yield put(hip_userUpdateError(error))
      throw error
    }
  }
}
