import { Divider, MenuList, List } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PagingMenuBar from './PagingMenuBar'
import HipMenuBar from './HipMenuBar'
import ApplicationLogs from './menuItems/ApplicationLogs'
import { Settings, SettingsApplications, Verified } from '@mui/icons-material'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import MenuListItem from './menuItems/MenuListItem'
import ExitListItem from './menuItems/ExitListItem'
import { getUserInfo } from '../common/store/selectors/user'
import { useSelector } from 'react-redux'

const MenuBar = () => {
  const { product_name: productName } = useSelector(getUserInfo)
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()

  return (
    <MenuList>
      {productName === 'HELP_IP' ? <HipMenuBar t={t} /> : <PagingMenuBar t={t} />}
      <Divider />
      <List>
        {hasPrivilege(Privileges.VIEW_SETTINGS) && (
          <MenuListItem
            title={t('zone_settings')}
            icon={<SettingsApplications />}
            to="/zone_settings"
            data-e2e="navbar-settings"
          />
        )}
        {hasPrivilege(Privileges.VIEW_SETTINGS) && (
          <MenuListItem
            title={t('general_settings')}
            icon={<Settings />}
            to="/general_settings"
            data-e2e="navbar-settings"
          />
        )}
        {hasPrivilege(Privileges.VIEW_LICENSE) && (
          <MenuListItem
            title={t('license_title')}
            icon={<Verified />}
            to="/license"
            data-e2e="navbar-license"
          />
        )}
        <ApplicationLogs t={t} />
        <ExitListItem data-e2e="navbar-exit" />
      </List>
    </MenuList>
  )
}

export default MenuBar
