import { useTranslation } from 'react-i18next'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import { Room } from '../types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { roomDeleteRequest } from '../actions/rooms/actions'

interface RoomsTablesProps {
  rooms: Room[]
}

const RoomsTables = ({ rooms }: RoomsTablesProps) => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const dispatch = useDispatch()
  const handleDelete = (id: number) => {
    dispatch(roomDeleteRequest(id))
  }

  const orderedRooms = [...rooms].sort((a, b) => a.orderId - b.orderId)

  return (
    <TableContainer component={Paper}>
      <Divider />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"> {t('orderId')}</TableCell>
            <TableCell align="center"> {t('name')}</TableCell>
            <TableCell align="center">{t('devices')}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedRooms?.map((room: Room) => (
            <TableRow key={room.id} data-e2e={'rooms-row'}>
              <TableCell align="center" component="th" scope="row" data-e2e={'rooms-cell-orderId'}>
                {room.orderId}
              </TableCell>
              <TableCell align="center" component="th" scope="row" data-e2e={'rooms-cell-name'}>
                {room.name}
              </TableCell>
              <TableCell align="center">
                {room.rcus.map((rcu) => (
                  <span key={rcu.id} data-e2e={'rooms-cell-rcu-' + rcu.id}>
                    {rcu.description}
                    {', '}
                  </span>
                ))}
              </TableCell>
              <TableCell align="right">
                <Grid>
                  {hasPrivilege(Privileges.EDIT_ROOMS) && (
                    <Button
                      component={Link}
                      to={`/rooms/${room.id}`}
                      data-e2e={'rooms-btn-edit-' + room.id}
                    >
                      <Edit color="primary" />
                    </Button>
                  )}
                  {hasPrivilege(Privileges.DELETE_ROOMS) && (
                    <Button
                      variant="text"
                      onClick={() => handleDelete(room.id)}
                      data-e2e={'rooms-btn-dlt-' + room.id}
                    >
                      <Delete color="error" />
                    </Button>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RoomsTables
