import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { deleteSettings } from '../../../api/settings/settingsDelete'
import { settingsDeleteSuccess, settingsError } from '../../actions/settings/actions'
import { Action } from '../root'
import { deletedItem } from '../../../../hip/types'

export function* sagaSettingsDelete({ payload }: Action<number>) {
  try {
    const response: deletedItem = yield call(deleteSettings, payload)
    yield put(settingsDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(settingsError(error.response.data as unknown))
    } else {
      yield put(settingsError(error))
      throw error
    }
  }
}
