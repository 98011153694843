import { call, put } from '@redux-saga/core/effects'
import { Action } from '../root'
import { settingsUpdateError, settingsUpdateSuccess } from '../../actions/settings/actions'
import { updateSettings } from '../../../api/settings/settingsUpdate'
import { Setting } from '../../../../types'
import axios from 'axios'

export function* sagaSettingsUpdate({ payload }: Action<Setting>) {
  try {
    const response: Setting = yield call(updateSettings, payload)
    yield put(settingsUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(settingsUpdateError(error.response.data as unknown))
    } else {
      yield put(settingsUpdateError(error))
      throw error
    }
  }
}
