import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CommonContent } from '../components/CommonContent'
import { useSnackbar } from 'notistack'
import { Radio } from '@mui/icons-material'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { useDispatch, useSelector } from 'react-redux'
import { filesStates } from '../common/store/selectors/files'
import RadiosTable from './RadiosTable'
import {
  filesCleanErrorRequest,
  filesCleanSuccessRequest,
  radioDeleteRequest,
  radiosRequest,
} from '../common/store/actions/files/actions'
import { Id } from '../hip/types'

export const RadiosManager = () => {
  const { hasPrivilege } = usePrivilege()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { radios, isLoading, error, success } = useSelector(filesStates)

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    dispatch(radiosRequest())
  }, [])

  useEffect(() => {
    console.log('isLoading')
  }, [isLoading, radios])

  useEffect(() => {
    if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(filesCleanErrorRequest())
    }
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(filesCleanSuccessRequest())
    }
  }, [error, success])

  const handleConfirmDelete = (id: Id) => {
    dispatch(radioDeleteRequest({ id: id }))
    handleClose()
  }

  return (
    <CommonContent title={t('radios')} titleIcon={<Radio style={{ color: 'white' }} />}>
      {hasPrivilege(Privileges.ADD_RADIOS) && (
        <Box mb={4}>
          <Button
            component={Link}
            to="/radios/add"
            variant="contained"
            color="success"
            startIcon={<Radio />}
          >
            {t('radios_add')}
          </Button>
        </Box>
      )}
      <RadiosTable
        radios={radios}
        handleConfirmDelete={handleConfirmDelete}
        handleClose={handleClose}
        open={open}
        setOpen={setOpen}
      />
    </CommonContent>
  )
}
