import { ButtonTypeMap } from '@mui/material'
import { HipIcon, HipIconProps } from './HipIcon'
import ButtonPanel from '../../dashboard/components/ButtonPanel'
import { MenuItemActions } from '../../dashboard/components/ContextMenu'

interface IProps extends HipIconProps {
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
  onClick?: () => void
  variant?: ButtonTypeMap['props']['variant']
  size?: 32 | 64 | 72 | 90 | 100 | 120 | 200
  // color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'disable'
  widthAndHeight?: number | string
  rightMenu?: MenuItemActions[]
}

export default function HipButton({
  onClick,
  children,
  variant = 'text',
  rightMenu,
  ...props
}: IProps) {
  return (
    <>
      <ButtonPanel
        variant={variant}
        rightMenu={rightMenu}
        widthAndHeight={props.size}
        {...{ onClick }}
      >
        <HipIcon {...props} />
      </ButtonPanel>
      {children}
    </>
  )
}
