import { feedbackParse, isPlayingFile } from '../../Utils'
import { DeviceEvent, IDeviceFeedback, TModeView } from '../../types'
import axios from 'axios'
import ButtonPanel from './ButtonPanel'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'

const play_file_feedback = 'play_file'
const alarm_file = 'alarm.raw'

interface IProps {
  'data-e2e'?: any
  device: DeviceEvent
  size?: 32 | 64 | 72 | 90 | 100 | 120 | 200
  viewMode: TModeView
}

const getAlarmColorOfDevice = (
  device: DeviceEvent,
  feedback: IDeviceFeedback,
): 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'disable' => {
  if (isPlayingFile(feedback)) {
    const playing = feedback[play_file_feedback]
    console.log('playing ', playing)
    // If it's the same file, show OK:
    if (playing === '=' + alarm_file.toUpperCase()) return 'success'
    // If there's another file playing:
    else return 'warning'
    // Anything else it's probably end of file ('!…')
  }

  if (device.state === 'READY') return 'warning'
  else if (device.state !== 'IDLE') return 'disable'

  const warn_if_feedback = ['receiving:1', 'sending', 'receiving:2', 'play_msg:2']
  for (const key of warn_if_feedback) if (feedback[key]) return 'warning'
  return 'primary'
}

const PlayAlarmButton = (props: IProps) => {
  const { device, size = 100, viewMode } = props
  if ('list' !== viewMode) return <></>

  // Type-safe parse feedback
  let feedback: IDeviceFeedback = {}
  try {
    feedback = feedbackParse(device.feedback)
    console.log(device.device_id, 'feedback', feedback)
  } catch (ex) {
    console.error('Invalid feedback', device.feedback, 'for device', device.device_id, '' + ex)
  }

  const onPlayAlarmClick = () => {
    //setLoading(true)
    const endpoint = `/v0/api/action/` + device.device_id + '/play/file/' + alarm_file
    if (feedback[play_file_feedback]) {
      axios.delete<any>(endpoint)
    } else {
      axios.post<any>(endpoint)
    }
  }

  const e2e = 'play-alarm-button' + (props['data-e2e'] ? '-' + props['data-e2e'] : '')
  return (
    <ButtonPanel
      onClick={onPlayAlarmClick}
      variant="contained"
      widthAndHeight={size}
      color={getAlarmColorOfDevice(device, feedback)}
      data-e2e={e2e}
      margin={'0 2em' /*viewMode == 'list' ? '0 1em' : '0 auto'*/}
    >
      <NotificationImportantIcon
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    </ButtonPanel>
  )
}

export default PlayAlarmButton
