import { PayloadAction, createReducer } from '@reduxjs/toolkit'
import { Group } from '../../api/types'
import {
  groupRequest,
  groupSuccess,
  groupError,
  groupsError,
  groupsRequest,
  groupsSuccess,
  groupDeleteRequest,
  groupDeleteSuccess,
  groupDeleteError,
  groupCreateRequest,
  groupCreateSuccess,
  groupCreateError,
  groupUpdateRequest,
  groupUpdateSuccess,
  groupUpdateError,
  groupCleanRequest,
  groupsCleanErrorRequest,
  groupsCleanSuccessRequest,
} from '../actions/groups/actions'
import { deletedItem } from '../../../hip/types'

interface GroupsState {
  group: Group
  groups: Group[]
  isLoading: boolean
  success: string
  error: string
}

export const initialState = (): GroupsState => ({
  group: null,
  groups: [],
  isLoading: false,
  success: '',
  error: '',
})

export const groupsReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(groupsRequest, (state) => {
      state.groups = []
      state.isLoading = true
    })
    .addCase(groupsSuccess, (state, { payload }) => {
      // TODO: move to backend
      state.groups = payload.filter((group) => group.name !== 'messages')
      state.isLoading = false
    })
    .addCase(groupsError, (state, { payload }) => {
      console.error('groupsReducer - groupsError', payload)
      state.error = payload
      state.groups = []
      state.isLoading = false
    })
    .addCase(groupRequest, (state) => {
      state.group = initialState().group
      state.isLoading = true
    })
    .addCase(groupSuccess, (state, { payload }) => {
      state.group = payload
      state.isLoading = false
    })
    .addCase(groupError, (state, { payload }) => {
      console.error('groupsReducer - groupError', payload)
      state.error = payload
      state.group = initialState().group
      state.isLoading = false
    })
    .addCase(groupCleanRequest, (state) => {
      state.group = initialState().group
    })
    .addCase(groupCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(groupCreateSuccess, (state) => {
      state.isLoading = false
      state.success = 'add_success'
    })
    .addCase(groupCreateError, (state, { payload }) => {
      console.error('groupsReducer - groupCreateError', payload)
      state.isLoading = false
      state.error = payload
    })
    .addCase(groupUpdateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(groupUpdateSuccess, (state) => {
      state.success = 'update_success'
      state.isLoading = false
    })
    .addCase(groupUpdateError, (state, { payload }) => {
      console.error('groupsReducer - groupUpdateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(groupDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(groupDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
      state.groups = state.groups.filter((group) => group.id !== Number(payload.id))
      state.success = 'delete_success'
      state.isLoading = false
    })
    .addCase(groupDeleteError, (state, { payload }) => {
      console.error('groupsReducer - groupDeleteError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(groupsCleanErrorRequest, (state) => {
      state.error = ''
    })
    .addCase(groupsCleanSuccessRequest, (state) => {
      state.success = ''
    })
})
