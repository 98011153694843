import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { UserDetail } from '../../../../types'
import { userDetailError, userDetailSuccess } from '../../actions/users/actions'
import { hipUserDetail } from '../../../../hip/api/hipUserDetail'

export function* sagaUserDetail({ payload }: Action<string>) {
  try {
    const response: UserDetail = yield call(hipUserDetail, payload)
    yield put(userDetailSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(userDetailError(error.response.data as unknown))
    } else {
      yield put(userDetailError(error))
      throw error
    }
  }
}
