import axios from 'axios'
//
import { UserLogin } from '../types'

// const axiosInstance = axios.create({
//   httpsAgent: new https.Agent({
//     rejectUnauthorized: false,
//   }),
// })

export const postLogin = async (payload: UserLogin) => {
  const response = await axios.post(`/v0/api/login`, payload)
  return response.data
}
