import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  tasksRequest,
  tasksSuccess,
  taskCreateError,
  taskCreateRequest,
  taskCreateSuccess,
  tasksError,
  tasksCleanErrorRequest,
  taskDeleteRequest,
  taskDeleteSuccess,
  taskDeleteError,
  taskDetailRequest,
  taskDetailSuccess,
  taskDetailError,
  taskUpdateRequest,
  // taskUpdateSuccess,
  taskUpdateError,
  tasksCleanSuccessRequest,
  exceptionsSuccess,
  exceptionsError,
  exceptionsCreateRequest,
  exceptionsCreateSuccess,
  exceptionsCreateError,
} from '../actions/tasks/actions'
import { ContextExceptions, PaginatedResponse, Tasks } from '../../../types'
import { deletedItem } from '../../../hip/types'

interface TasksState {
  tasks: {
    page: number | null
    limit: number | null
    pages: number | null
    total: number | null
    items: Tasks[]
  }
  exceptions: ContextExceptions[]
  task: Partial<Tasks>
  isLoading: boolean
  success: string
  error: string
}

export const initialState = (): TasksState => ({
  tasks: {
    page: null,
    limit: null,
    pages: null,
    total: null,
    items: [],
  },
  exceptions: [],
  task: null,
  isLoading: false,
  success: '',
  error: '',
})

export const tasksReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(tasksRequest, (state) => {
      state.isLoading = true
    })
    .addCase(tasksSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const { page, limit, pages, total, items } = payload
      state.tasks = {
        page,
        limit,
        pages,
        total,
        items: items as Tasks[],
      }
      state.isLoading = false
    })
    .addCase(tasksError, (state, { payload }) => {
      console.error('taskReducer - getTasksError', payload)
      state.error = payload.message
      state.tasks = initialState().tasks
      state.isLoading = false
    })
    .addCase(exceptionsSuccess, (state, { payload }: PayloadAction<ContextExceptions[]>) => {
      state.exceptions = payload
    })
    .addCase(exceptionsError, (state, { payload }) => {
      console.error('taskReducer - getExceptionsError', payload)
      state.error = payload.message
      state.exceptions = initialState().exceptions
    })
    .addCase(taskDetailRequest, (state) => {
      state.task = initialState().task
      state.isLoading = true
    })
    .addCase(taskDetailSuccess, (state, { payload }: PayloadAction<Tasks>) => {
      state.task = payload
      state.isLoading = false
    })
    .addCase(taskDetailError, (state, { payload }) => {
      console.error('taskReducer - taskDetailError', payload)
      state.error = payload.message
      state.task = initialState().task
      state.isLoading = false
    })
    .addCase(taskCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(taskCreateSuccess, (state) => {
      state.success = 'add_success'
      state.isLoading = false
    })
    .addCase(taskCreateError, (state, { payload }) => {
      console.error('taskReducer - taskCreateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(exceptionsCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(exceptionsCreateSuccess, (state) => {
      state.success = 'exceptions_added'
      state.isLoading = false
    })
    .addCase(exceptionsCreateError, (state, { payload }) => {
      console.error('taskReducer - exceptionsCreateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(taskUpdateRequest, (state) => {
      state.isLoading = true
    })
    // .addCase(taskUpdateSuccess, (state, { payload }: PayloadAction<Tasks>) => {
    //   const updatedItems = state.tasks.items.filter((task) => task.id !== payload.id)
    //   const updatedTotal = updatedItems.length

    //   state.tasks = {
    //     ...state.tasks,
    //     total: updatedTotal,
    //     items: updatedItems,
    //   }
    //   state.task = payload
    //   state.success = 'update_success'
    //   state.isLoading = false
    // })
    .addCase(taskUpdateError, (state, { payload }) => {
      console.error('taskReducer - taskUpdateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(taskDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(taskDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
      const currentTasks = state.tasks
      const updatedTasks = {
        ...currentTasks,
        total: currentTasks.total ? currentTasks.total - 1 : 0,
        items: currentTasks.items.filter((task) => task.id !== Number(payload.id)),
      }
      state.tasks = updatedTasks
      state.success = 'delete_success'
      state.isLoading = false
    })
    .addCase(taskDeleteError, (state, { payload }) => {
      console.error('tasksReducer - taskDeleteError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(tasksCleanErrorRequest, (state) => {
      state.error = ''
    })
    .addCase(tasksCleanSuccessRequest, (state) => {
      state.success = ''
    })
})
