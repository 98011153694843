import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IProps extends LinkProps {
  title: string
  icon: ReactNode
}

const MenuListItem: FC<IProps> = ({ title, icon, ...props }) => {
  const { t } = useTranslation()

  return (
    <ListItem button component={Link} style={{ marginTop: '2px' }} {...props}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={t(title)} />
    </ListItem>
  )
}

export default MenuListItem
