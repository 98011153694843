// /DraggableButton.tsx

import { Dispatch, SetStateAction } from 'react'
import { MenuItemActions } from '../../dashboard/components/ContextMenu'
import { TDeviceSize, TModeView } from '../../types'
import { RoomFeedback } from '../types'
import RoomButton from './RoomButton'

interface IProps {
  isProfile: number
  room: RoomFeedback
  deviceSize: TDeviceSize
  onlyEvents: boolean
  viewMode: TModeView
  rightMenu: MenuItemActions[]
  index: number
  setOrderedRooms: Dispatch<SetStateAction<RoomFeedback[]>>
  reorderRooms: (draggedIndex: number, targetIndex: number) => void
}

const DraggableButton = ({
  isProfile,
  room,
  deviceSize,
  onlyEvents,
  viewMode,
  rightMenu,
  index,
  reorderRooms,
}: IProps) => {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('draggedIndex', index.toString())
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, targetIndex: number) => {
    e.preventDefault()
    const draggedIndex = parseInt(e.dataTransfer.getData('draggedIndex'), 10)
    reorderRooms(draggedIndex, targetIndex)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  return (
    <div
      draggable
      onDragStart={(e) => handleDragStart(e, index)}
      onDragOver={handleDragOver}
      onDrop={(e) => handleDrop(e, index)}
    >
      <RoomButton
        isProfile={isProfile}
        room={room}
        deviceSize={deviceSize}
        onlyEvents={onlyEvents}
        viewMode={viewMode}
        rightMenu={rightMenu}
      />
    </div>
  )
}

export default DraggableButton
