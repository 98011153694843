import axios from 'axios'

import { PageSet } from '../types'

export const getRcus = async ({ limit, page }: PageSet) => {
  const response = await axios.get(
    `/v2/api/rcus?available=false&limit=${limit}&page=${page}&sortDir=asc&sortBy=device_id`,
  )
  return response.data
}
