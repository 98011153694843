import axios from 'axios'

import { TaskFormInputs } from '../../../tasks/TaskForm'

export const taskUpdate = async (payload: { id: number; type: string; body: TaskFormInputs }) => {
  const { id, type, body } = payload
  console.log(body)
  const response = await axios.patch(`/v2/api/cron-tasks/${type}/${id}`, body)
  return response.data
}
