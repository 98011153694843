import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { roomCreate } from '../../../api/roomCreate'
import { roomCreateError, roomCreateSuccess } from '../../../actions/rooms/actions'
import { Action } from '../../../../common/store/sagas/root'
import { Room } from '../../../types'
import { getRooms } from '../../../api/roomsGetter'
import { PaginatedResponse } from '../../../../types'

export function* sagaRoomCreate({ payload }: Action<Room>) {
  try {
    yield call(roomCreate, payload)
    const response: PaginatedResponse = yield call(getRooms, { limit: '4', page: '1' })
    yield put(roomCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(roomCreateError(error.response.data as unknown))
    } else {
      yield put(roomCreateError(error))
      throw error
    }
  }
}
