import { Box, Typography } from '@mui/material'
import { useSSE } from 'react-hooks-sse'
import { DeviceEvent } from '../../types'
import DeviceButton from './DeviceButton'

const showMACinDeviceButton = false

export const SoniDevices = () => {
  const allDevices = useSSE<DeviceEvent[]>('devices', [])
  const devices = allDevices.filter(
    (device) => device.type !== 'rcu' && device.type !== 'microphone' && device.type !== 'speaker',
  )

  return (
    <>
      {devices.map((device) => (
        <Box key={device.id} m={2}>
          <DeviceButton viewMode="large-icon" device={device} size={72} />
          <Typography variant="caption" display="flex" justifyContent="center">
            {device.description}
          </Typography>
          {showMACinDeviceButton ? (
            <Typography variant="caption" display="flex" justifyContent="center">
              {device.device_id}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      ))}
    </>
  )
}
