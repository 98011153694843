import { DeviceState, IDeviceFeedback } from './types'

/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string) {
  const date = new Date()
  const value = val

  // Set it expire in 7 days
  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts !== undefined && parts.length === 2) {
    return parts.pop().split(';').shift()
  }
  return ''
}

export function deleteCookie(name: string) {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
}

export const getDeviceColorByState = (
  state: DeviceState,
): 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'disable' => {
  if (state === 'IDLE') {
    return 'primary'
  }
  if (state === 'READY') {
    return 'success'
  }
  return 'disable'
}

export function feedbackParse(json: string): IDeviceFeedback {
  //const dict: IFeedback = {}
  const parsed = JSON.parse(json)
  if (!parsed) return {} // Tratar null como {}
  if (typeof parsed !== 'object') {
    throw Error('JSON is not a dictionary')
  }
  for (const key in parsed) {
    if (typeof key !== 'string') {
      throw Error('Key is not a string: ' + JSON.stringify(key))
    }
    const value = parsed[key]
    if (typeof value !== 'boolean' && typeof value !== 'string') {
      throw Error(
        'Value of ' + JSON.stringify(key) + ' is not a valid type: ' + JSON.stringify(value),
      )
    }
  }
  return parsed
}

export const isPlayingFile = (feedback: IDeviceFeedback) =>
  typeof feedback['play_file'] === 'string' && feedback['play_file'][0] === '='

export function randomNumber(max: number, min: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

//mqtt_topicValue random generator
export function topicGenerator() {
  const allowCharacters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let topic = 'au'

  for (let i = 0; i < 2; i++) {
    const randomIndex = Math.floor(Math.random() * allowCharacters.length)
    topic += allowCharacters.charAt(randomIndex)
  }

  return topic
}

export const multicast_acrt = randomNumber(52000, 52999)
//Agrega un valor de puerto aleatorio por defecto (52000 - 52999)
export const ipMulticast = randomNumber(0, 255)
//Agrega un valor por defecto aleatorio de una IP multicast (224.0.0.1 a 224.0.0.225)

export enum IEntityViewMode {
  ADD,
  EDIT,
  VIEW,
}

export const EditViewModeE2E: { [key in IEntityViewMode]: string } = {
  [IEntityViewMode.ADD]: 'add',
  [IEntityViewMode.EDIT]: 'edit',
  [IEntityViewMode.VIEW]: 'view',
}

export const entityViewOkBtnName = (viewMode: IEntityViewMode) => ['add', 'save', 'ok'][viewMode]

export const getProductName = (productName: string): string => {
  switch (productName) {
    case 'PAGING':
      return 'Paging System'
    case 'HELP_IP':
      return 'Help IP System'
    default:
      return 'Access Control'
  }
}
