import React, { useEffect } from 'react'
import { CommonContent } from '../components/CommonContent'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import axios from 'axios'
import { Delete, Edit, PermContactCalendar } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Link, useNavigate } from 'react-router-dom'

import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../common/store/selectors/user'

const useStyles = makeStyles()((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    maxWidth: 'sm',
  },
  paper: {
    padding: theme.spacing(2),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  button: {
    margin: theme.spacing(1),
  },
  click_cancelar: {
    '&:hover': {
      backgroundColor: '#3251AD',
      color: 'white',
    },
  },
  click_delete: {
    '&:hover': {
      backgroundColor: '#FF6466',
      color: 'white',
    },
  },
}))

interface IProfiles {
  id: number
  name: string
  editable: number
}

export const Profiles = () => {
  const { product_name } = useSelector(getUserInfo)
  const isPaging = product_name === 'PAGING'
  const { hasPrivilege } = usePrivilege()
  const { t } = useTranslation()
  const [rows, setRows] = React.useState<IProfiles[]>([])
  const { classes } = useStyles()
  const [open, setOpen] = React.useState(false)
  const [profileToDelete, setProfileToDelete] = React.useState<Partial<IProfiles>>({})
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    axios.get<IProfiles[]>(`/v0/api/profile`).then((result) => {
      let profiles = result.data
      if (isPaging) {
        profiles = profiles.filter((profile) => ![2, 3, 4, 5, 6, 7, 8].includes(profile.id))
      }
      setRows(profiles)
    })
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>, profile: IProfiles) => {
    console.log(event.target)
    console.log(profile)
    setProfileToDelete(profile)
    setOpen(true)
  }

  const handleConfirmDelete = () => {
    axios.delete(`/v0/api/profile/` + profileToDelete.id).then(() => {
      enqueueSnackbar('Delete success!!', { variant: 'success' })
      navigate('/profiles')
      axios.get<IProfiles[]>(`/v0/api/profile`).then((result) => {
        setRows(result.data)
      })
      setOpen(false)
    })
  }

  const e2eprefix = 'profiles-'

  return (
    <CommonContent
      title={t('profiles')}
      titleIcon={<PermContactCalendar style={{ color: 'white' }} />}
    >
      {hasPrivilege(Privileges.ADD_PROFILES) && (
        <Box mb={4}>
          <Button
            data-e2e={e2eprefix + 'btn-add'}
            component={Link}
            to="/profiles/add"
            variant="contained"
            color="success"
            startIcon={<PermContactCalendar />}
          >
            Add Profile
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Divider />
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ textAlign: 'center' }}>
            <TableRow>
              <TableCell align="center">{t('id')}</TableCell>
              <TableCell align="center">{t('name')}</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} data-e2e={e2eprefix + 'row-' + row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  data-e2e={e2eprefix + 'row-cell-id'}
                >
                  {row.id}
                </TableCell>
                <TableCell align="center" data-e2e={e2eprefix + 'row-cell-name'}>
                  {row.name}
                </TableCell>
                <TableCell align="center" data-e2e={e2eprefix + 'row-cell-actions'}>
                  <Grid>
                    {hasPrivilege(Privileges.EDIT_PROFILES) && row.editable ? (
                      <Button
                        component={Link}
                        to={'/profiles/' + row.id}
                        data-e2e={e2eprefix + 'row-cell-actions-btn-edit'}
                      >
                        <Edit color="primary" />
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid>
                    {hasPrivilege(Privileges.DELETE_PROFILES) && row.editable ? (
                      <Button
                        variant="text"
                        onClick={(e) => handleClick(e, row)}
                        data-e2e={e2eprefix + 'row-cell-actions-btn-delete'}
                      >
                        <Delete color="error" />
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e={e2eprefix + 'dlg-delete'}
      >
        <DialogTitle id="responsive-dialog-title">{t('profile_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('profile_delete_ask')}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            data-e2e={e2eprefix + 'dlg-delete-btn-delete'}
            component={Link}
            to={'/profile/' + profileToDelete.id}
            className={classes.click_delete}
            onClick={() => handleConfirmDelete()}
            variant="outlined"
            color="secondary"
          >
            {t('delete')}
          </Button>
          <Button
            data-e2e={e2eprefix + 'dlg-delete-btn-cancel'}
            className={classes.click_cancelar}
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </CommonContent>
  )
}
