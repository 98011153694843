import { useTranslation } from 'react-i18next'
import { CommonContent } from '../components/CommonContent'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import { Control, Controller, FieldValues, useForm } from 'react-hook-form'
import { TextField, Grid, Button, Card, CardContent, CardHeader } from '@mui/material'
import { EntityViewOkBtn } from '../components/EntityViewOkBtn'
import { useNavigate, useParams } from 'react-router-dom'
import { IEntityViewMode } from '../Utils'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { IPrivilegeV2, IProfileV2 } from '../common/api/types'

import { ICheckboxItem } from '../components/FormCheckboxGroup'
import FormCheckboxGroup from '../components/FormCheckboxGroup/FormCheckboxGroup'
import { useSnackbar } from 'notistack'

interface IFormInputs {
  name: string
  editable: boolean
  privileges: ICheckboxItem[]
}

const ProfilesForm2 = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [privileges, setPrivileges] = useState<IPrivilegeV2[]>([])
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<IFormInputs>({
    defaultValues: {
      name: '',
      editable: true,
      privileges: [],
    },
  })
  const viewMode = params.id === 'add' ? IEntityViewMode.ADD : IEntityViewMode.EDIT

  const submitForm = (data: IFormInputs) => {
    const profile = {
      name: data.name,
      editable: data.editable,
      privileges: data.privileges.map((p) => p.id),
    }
    if (viewMode === IEntityViewMode.ADD) {
      axios.post(`/v2/api/profiles`, profile).then(() => {
        enqueueSnackbar('Add success!!', { variant: 'success' })
        navigate('/profiles')
      })
    }
    if (viewMode === IEntityViewMode.EDIT) {
      axios.patch(`/v2/api/profiles/` + params.id, profile).then(() => {
        enqueueSnackbar('Edit success!!', { variant: 'success' })
        navigate('/profiles')
      })
    }
  }

  useEffect(() => {
    const profileId = params.id === 'add' ? undefined : params.id
    if (profileId) {
      console.log('get profiles', params)
      axios.get<IProfileV2>(`/v2/api/profiles/` + profileId).then((result) => {
        console.log(result.data)
        reset(
          {
            name: result.data.name,
            editable: result.data.editable,
            privileges: result.data.privileges.map((p) => ({ id: p.code, label: p.description })),
          },
          { keepSubmitCount: true },
        )
      })
    }
  }, [params.id])

  useEffect(() => {
    axios.get<IPrivilegeV2[]>(`/v0/api/privilege`).then((result) => {
      setPrivileges(result.data)
    })
  }, [])

  const privilegesGroupedByCategory = privileges.reduce((acc, privilege) => {
    const category = privilege.category
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(privilege)
    return acc
  }, {} as { [key: string]: IPrivilegeV2[] })

  return (
    <CommonContent
      title={t('profiles')}
      titleIcon={<PermContactCalendarIcon style={{ color: 'white' }} />}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              style={{ paddingBottom: 20 }}
              fullWidth
              type="text"
              id="name"
              value={value}
              onChange={onChange}
              label={t('profile_name')}
              placeholder="Profile name"
              variant="outlined"
            />
          )}
        />
        <Grid container spacing={4}>
          {Object.entries(privilegesGroupedByCategory).map(([category, privileges]) => (
            <Grid key={category} item xs={12} md={3}>
              <Card>
                <CardHeader title={category} />
                <CardContent>
                  <FormCheckboxGroup
                    control={control as unknown as Control<FieldValues>}
                    id="privileges"
                    items={privileges.map((p) => ({ id: p.code, label: p.description }))}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                navigate('/profiles')
              }}
              variant="outlined"
              color="secondary"
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <EntityViewOkBtn disabled={!isDirty} viewMode={viewMode} type="submit" />
          </Grid>
        </Grid>
      </form>
    </CommonContent>
  )
}

export default ProfilesForm2
