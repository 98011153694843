import axios from 'axios'

import { Rcu } from '../types'

export const rcuUpdate = async (payload: Rcu) => {
  const rcuId = payload.id
  const response = await axios.patch(`/v2/api/rcus/${rcuId}`, {
    device_id: payload.device_id,
    description: payload.description,
  })
  return response.data
}
