import { useTranslation } from 'react-i18next'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { User_Key } from '../types'
import { keyDeleteRequest, hip_userDeleteRequest } from '../actions/hipUsers/actions'
import { useDispatch } from 'react-redux'

interface HipUsersTablesProps {
  userType: string
  hipUsers: User_Key[]
  setAddKey: (addKey: { add: boolean; id: number }) => void
  setOpen: (open: boolean) => void
}

const HipUsersTables = ({ userType, hipUsers, setAddKey, setOpen }: HipUsersTablesProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { hasPrivilege } = usePrivilege()

  const handleAddKey = (HipUserId: number) => {
    setAddKey({ add: true, id: HipUserId })
    setOpen(false)
  }

  const handleDeleteKey = (keyId: number) => {
    dispatch(keyDeleteRequest(keyId))
  }

  const handleDelete = (id: number) => {
    dispatch(hip_userDeleteRequest(id))
  }

  return (
    <TableContainer component={Paper}>
      <Divider />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"> {t('username')}</TableCell>
            <TableCell align="center">{t('name')}</TableCell>
            {userType !== 'admission' && <TableCell align="center">{t('prox_card')}</TableCell>}
            <TableCell align="center">{t(`edit_${userType}`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hipUsers?.map((hipUser: User_Key) => (
            <TableRow key={hipUser.id} data-e2e={'nrs-row' + hipUser.id}>
              <TableCell align="center" component="th" scope="row" data-e2e={'nrs-cell-user'}>
                {hipUser.username}
              </TableCell>
              <TableCell align="center" data-e2e={'nrs-cell-name'}>
                {hipUser.name}
              </TableCell>
              {userType !== 'admission' && (
                <TableCell align="center">
                  {hipUser.access_key ? (
                    <>
                      <Typography data-e2e={'nrs-key'}>N°: {hipUser.access_key.key}</Typography>
                      <Grid>
                        {hasPrivilege(Privileges.EDIT_KEYS) && (
                          <Button
                            data-e2e="key-btn-edit"
                            variant="text"
                            onClick={() => handleAddKey(hipUser.id)}
                          >
                            <Edit color="primary" />
                          </Button>
                        )}
                        {hasPrivilege(Privileges.DELETE_KEYS) && (
                          <Button
                            variant="text"
                            data-e2e="key-btn-dlt"
                            onClick={() => handleDeleteKey(hipUser.access_key.id)}
                          >
                            <Delete color="error" />
                          </Button>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <Grid>
                      {hasPrivilege(Privileges.ADD_KEYS) && (
                        <Button
                          data-e2e="key-ADD-btn"
                          variant="contained"
                          color="success"
                          onClick={() => handleAddKey(hipUser.id)}
                        >
                          {t('add')}
                        </Button>
                      )}
                    </Grid>
                  )}
                </TableCell>
              )}
              <TableCell align="center">
                <Grid>
                  {hasPrivilege(Privileges.EDIT_HIP_PROFILES) && (
                    <Button
                      component={Link}
                      to={`/${userType}/${hipUser.id}`}
                      data-e2e={'nrs-btn-edit-' + hipUser.id}
                    >
                      <Edit color="primary" />
                    </Button>
                  )}
                  {hasPrivilege(Privileges.DELETE_HIP_PROFILES) && (
                    <Button
                      variant="text"
                      onClick={() => handleDelete(hipUser.id)}
                      data-e2e={'nrs-btn-dlt-' + hipUser.id}
                    >
                      <Delete color="error" />
                    </Button>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default HipUsersTables
