import Admin from './Admin'
import axios from 'axios'
import './i18n/config'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { getUserInfo } from './common/store/selectors/user'
import { userCleanErrorRequest, userCleanSuccessRequest } from './common/store/actions/user/actions'
import Dashboard2 from './dashboard/Dashboard2'
import RoomsBoard from './hip/boards/RoomsBoard'
import SignIn from './SigIn'
import Video from './video/Video'

axios.defaults.withCredentials = true

export default function App() {
  const dispatch = useDispatch()
  const userInfoState = useSelector(getUserInfo)
  const { enqueueSnackbar } = useSnackbar()
  const { loged, success, error } = useSelector(getUserInfo)
  const { t } = useTranslation()

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(userCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(userCleanErrorRequest())
    }
  }, [success, error])

  const login = userInfoState?.id && userInfoState?.active

  return (
    <>
      <Routes>
        {!login && !loged ? (
          <>
            <Route path="/*" element={<SignIn />}></Route>
          </>
        ) : (
          <Route path="/*" element={<Admin />}></Route>
        )}
        {!login && !loged ? (
          <Route path="/roomsboard" element={<SignIn />}></Route>
        ) : (
          <>
            <Route path="/roomsboard" element={<RoomsBoard />} />
            <Route path="/mobile/roomsboard" element={<RoomsBoard isMobileMode />} />
          </>
        )}
        {!login && !loged ? (
          <Route path="/dashboard" element={<SignIn />}></Route>
        ) : (
          <>
            <Route path="/dashboard" element={<Dashboard2 />} />
            <Route path="/mobile/dashboard" element={<Dashboard2 isMobileMode />} />
          </>
        )}
        {!login && !loged ? (
          <Route path="/video" element={<SignIn />}></Route>
        ) : (
          <>
            <Route path="/video" element={<Video />} />
          </>
        )}
      </Routes>
    </>
  )
}
