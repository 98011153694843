import { Box, Typography } from '@mui/material'
import { feedbackParse, getDeviceColorByState, isPlayingFile } from '../../Utils'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ButtonPanel from './ButtonPanel'
import CircleIcon from '@mui/icons-material/Circle'
import { DeviceEvent, IDeviceFeedback, TModeView } from '../../types'
import VolumeDownIcon from '@mui/icons-material/VolumeDown'
import MicOffIcon from '@mui/icons-material/MicOff'
import MicIcon from '@mui/icons-material/Mic'
import DevicesOtherIcon from '@mui/icons-material/DevicesOther'

import { MenuItemActions } from './ContextMenu'

interface IPropsDeviceIcon {
  device: DeviceEvent
}

const DeviceIcon = ({ device }: IPropsDeviceIcon) => {
  if (device.type === 'speaker') {
    return (
      <VolumeDownIcon
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    )
  }
  if (device.type === 'microphone') {
    if (device.state === 'READY') {
      return (
        <MicIcon
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
      )
    }
    return (
      <MicOffIcon
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    )
  }
  return (
    <DevicesOtherIcon
      sx={{
        width: '100%',
        height: '100%',
      }}
    />
  )
}

const getStyle = (viewMode: TModeView) => {
  switch (viewMode) {
    case 'medium-icon':
      return {
        indicatorsPosition: 'before',
        indicatorsStyle: {},
        rightIndicatorsStyle: {},
      }
    case 'small-icon':
      return {
        indicatorsPosition: 'before',
        indicatorsStyle: {},
        rightIndicatorsStyle: {
          fontSize: '0.9em',
          margin: '0.2em',
        },
      }
    case 'list':
      return {
        indicatorsPosition: 'before',
        indicatorsStyle: { margin: '0 1em', width: '3.5em' },
        rightIndicatorsStyle: {
          fontSize: '0.9em',
          margin: '0.3em 0',
        },
      }
    default:
      return {
        indicatorsPosition: 'before',
        indicatorsStyle: {},
        rightIndicatorsStyle: {},
      }
  }
}

interface IProps {
  'data-e2e'?: any
  device: DeviceEvent
  size?: 32 | 64 | 72 | 90 | 100 | 120 | 200
  viewMode: TModeView
  rightMenu?: MenuItemActions[]
}

const DeviceButton = (props: IProps) => {
  const { device, size = 100, viewMode } = props
  const viewStyle = getStyle(viewMode)
  const e2e = props['data-e2e'] ?? 'device-button-' + device.device_id
  const [loading, setLoading] = useState(false)

  // Type-safe parse feedback
  let feedback: IDeviceFeedback = {}
  try {
    feedback = feedbackParse(device.feedback)
    //console.log(device.device_id, 'feedback', feedback)
  } catch (ex) {
    console.error('Invalid feedback', device.feedback, 'for device', device.device_id, '' + ex)
  }

  const onClickHandle = () => {
    setLoading(true)
    if (device.state === 'IDLE') {
      axios.post<any>(`/v0/api/actions/` + device.device_id + '/listen')
    } else if (device.state === 'READY') {
      axios.post<any>(`/v0/api/actions/` + device.device_id + '/stop')
    }
  }

  enum IndicatorState {
    Off = 0,
    On,
  }

  const indicatorState = (assertion: any) => (assertion ? IndicatorState.On : IndicatorState.Off)

  type PaletteColor =
    | 'disabled'
    | 'action'
    | 'inherit'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'warning'
    | 'statusOn'
    | 'statusOff'
    | 'invisible'

  interface IIndicatorProps {
    id?: string
    state: IndicatorState
    colorOn?: PaletteColor
    colorOff?: PaletteColor
  }

  const FeedbackIndicator = (props: IIndicatorProps) => {
    const colorOn = props.colorOn ?? 'statusOn'
    const colorOff = props.colorOff ?? 'statusOff'
    const color = props.state == IndicatorState.On ? colorOn : colorOff
    const testid = 'CircleIcon' + (props.id ? '-' + props.id : '')
    const e2e = testid + '-' + props.state
    return color === 'invisible' ? (
      <></>
    ) : (
      <CircleIcon fontSize="inherit" color={color} data-testid={testid} data-e2e={e2e} />
    )
  }

  const FeedbackLeftIndicators = () => {
    const alert = indicatorState(feedback['alert'])
    //device.feedback === 'alert' || device.feedback?.startsWith('alert:'),
    return (
      <Typography m={1} variant="body1">
        <FeedbackIndicator id="alert" state={alert} colorOn="error" colorOff="invisible" />
      </Typography>
    )
  }

  const FeedbackRightIndicators = () => {
    //const fontSize = viewMode == 'small-icon' ? '0.9em' : '1em'
    if (device.type === 'microphone') {
      const sending = indicatorState(feedback['sending'])
      return (
        <Typography m={1} variant="body1" style={viewStyle.rightIndicatorsStyle}>
          <FeedbackIndicator id="sending" state={sending} />
        </Typography>
      )
    } else {
      // Comprobación combinada para "receiving:2" y "play_file:2"
      const receiving2 =
        feedback['receiving:2'] || feedback['play_file:2'] ? IndicatorState.On : IndicatorState.Off

      // Manejando receiving1 normalmente
      const receiving1 = isPlayingFile(feedback)
        ? IndicatorState.On
        : indicatorState(feedback['receiving:1'])
      return (
        <Typography m={1} variant="body1" style={viewStyle.rightIndicatorsStyle} color="success">
          <FeedbackIndicator id="receiving2" state={receiving2} />
          <FeedbackIndicator id="receiving1" state={receiving1} />
        </Typography>
      )
    }
  }

  useEffect(() => {
    setLoading(false)
  }, [device.state])

  const ListIndicators = () => {
    return (
      viewMode === 'list' && (
        <Box
          display="flex"
          justifyContent="right"
          style={viewStyle.indicatorsStyle}
          ml={6}
          data-e2e="ListIndicators"
        >
          <FeedbackLeftIndicators />
          <FeedbackRightIndicators />
        </Box>
      )
    )
  }

  return (
    <>
      {viewStyle.indicatorsPosition == 'before' && <ListIndicators />}
      <ButtonPanel
        onClick={onClickHandle}
        variant="contained"
        widthAndHeight={size}
        color={getDeviceColorByState(device.state)}
        loading={loading}
        data-e2e={e2e}
        margin={viewMode == 'list' ? '0 1em' : '0 auto'}
        rightMenu={props.rightMenu}
      >
        {viewMode !== 'list' && (
          <>
            <Box display="flex" justifyContent="left" width="100%">
              <FeedbackLeftIndicators />
            </Box>
            <Box display="flex" justifyContent="right" width="100%">
              <FeedbackRightIndicators />
            </Box>
          </>
        )}
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Box m={4} width="100%" height="100%">
            <DeviceIcon device={device} />
          </Box>
        </Box>
      </ButtonPanel>
      {viewStyle.indicatorsPosition == 'after' && <ListIndicators />}
    </>
  )
}

export default DeviceButton
