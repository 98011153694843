import axios from 'axios'

import { RadioFormValues } from '../../../radio_manager/RadiosForm'

export const radioCreate = async (payload: RadioFormValues) => {
  const { name, url } = payload
  const response = await axios.post(`/v0/api/radios`, {
    name,
    url,
  })
  return response.data
}
