import { createReducer } from '@reduxjs/toolkit'
import { IProfile } from '../../api/types'
import {
  userProfileError,
  userProfileRequest,
  userProfileSuccess,
} from '../actions/profile/actions'

interface UserProfileState {
  isLoading: boolean
  user_id?: string
  profiles: IProfile[]
  devices?: string[]
  rooms?: number[]
  success: string
  error: string
}

export const initialState = (): UserProfileState => {
  return {
    isLoading: false,
    user_id: undefined,
    profiles: undefined,
    devices: undefined,
    rooms: undefined,
    success: '',
    error: '',
  }
}

export const userProfileReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(userProfileRequest, (state) => {
      state.isLoading = true
      state.user_id = initialState().user_id
      state.devices = initialState().devices
      state.rooms = initialState().rooms
    })
    .addCase(userProfileSuccess, (state, { payload }) => {
      state.isLoading = false
      state.user_id = payload.user_id
      state.profiles = payload.profiles
      state.devices = payload._embedded.devices
      state.rooms = payload._embedded.rooms
    })
    .addCase(userProfileError, (state, { payload }) => {
      console.error('profileReducer - userProfileError', payload)
      if (typeof payload === 'string') {
        if (!payload.includes('502 Bad Gateway')) {
          state.error = payload
        }
      } else if (typeof payload === 'object' && payload !== null) {
        const { message, statusCode } = payload
        if (statusCode !== 502 && !message.includes('Bad Gateway')) {
          state.error = message || 'Unknown error'
        }
      }
      state.isLoading = true
      state.profiles = []
      state.user_id = initialState().user_id
      state.devices = initialState().devices
      state.rooms = initialState().rooms
    })
})
