import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { CommonContent } from '../../components/CommonContent'
import { CircularProgress, Typography } from '@mui/material'
import { SettingsRemote } from '@mui/icons-material'
import { rcusRequest, rcuCleanErrorRequest, rcuCleanSuccessRequest } from '../actions/rcus/actions'
import { rcusStates } from '../store/selectors/rcus'
import RcusTables from './RcusTables'
import RcusForm from './RcusForm'
import Paginate from '../components/Paginate'

const RcusView = () => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { rcus, isLoading, success, error } = useSelector(rcusStates)
  const [selectedPage, setSelectedPage] = useState('1')

  useEffect(() => {
    dispatch(rcusRequest({ limit: '4', page: selectedPage }))
  }, [selectedPage])

  useEffect(() => {
    if (rcus.items.length > 0) {
      console.log('rendering', rcus)
    }
  }, [rcus])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(rcuCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(rcuCleanErrorRequest())
    }
  }, [success, error])

  return (
    <CommonContent
      title={t('rcu_devices')}
      titleIcon={<SettingsRemote style={{ color: 'white' }} />}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          {rcus.items.length ? (
            <RcusTables rcus={rcus.items} />
          ) : (
            <Typography variant="h6" color="text.secondary" align="center" p={10}>
              {t('no_items')}
            </Typography>
          )}
          {!rcus.items.length && selectedPage == '1' ? null : (
            <Paginate
              pages={rcus.pages}
              currentPage={rcus.page}
              setSelectedPage={setSelectedPage}
            />
          )}
          {hasPrivilege(Privileges.ADD_DEVICE) && <RcusForm />}
        </>
      )}
    </CommonContent>
  )
}

export default RcusView
