import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { NewUserData, UserDetail } from '../../../../types'
import { userCreate } from '../../../../common/api/users/userCreate'
import {
  hip_userCreateSuccess,
  hip_userCreateError,
  keyCreateError,
} from '../../../actions/hipUsers/actions'
import { addProfile } from '../../../api/userAddProfile'
import { hipUserDetail } from '../../../api/hipUserDetail'
import { keyCreate } from '../../../api/keyCreate'

export function* sagaHipUserCreate({ payload }: Action<NewUserData>) {
  try {
    const { data, profiles, key } = payload
    const response: UserDetail = yield call(userCreate, data)
    const profileData = {
      user_id: response.id,
      profiles: profiles,
    }
    const response2: UserDetail = yield call(addProfile, profileData)
    if (key) {
      try {
        const keyData = {
          key: key,
          user_id: response2.user_id,
        }
        yield call(keyCreate, keyData)
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          yield put(keyCreateError(error.response.data as unknown))
        } else {
          yield put(keyCreateError(error))
          throw error
        }
      }
    }
    const userCreated: UserDetail = yield call(hipUserDetail, response2.user_id.toString())
    yield put(hip_userCreateSuccess(userCreated))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(hip_userCreateError(error.response.data as unknown))
    } else {
      yield put(hip_userCreateError(error))
      throw error
    }
  }
}
