import axios from 'axios'

import { RcuFormValues } from '../types'

export const rcuCreate = async (payload: RcuFormValues) => {
  const { device_id, description } = payload
  const response = await axios.post(`/v2/api/rcus`, {
    device_id,
    description,
  })
  return response.data
}
