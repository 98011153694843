import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { availableRoomsSuccess, availableRoomsError } from '../../../actions/rooms/actions'
import { getAvailableDevices } from '../../../api/availableDevices'
import { PaginatedResponse } from '../../../../types'

export function* sagaGetAvailableRooms() {
  try {
    const response: PaginatedResponse = yield call(getAvailableDevices, 'rooms')
    yield put(availableRoomsSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(availableRoomsError(error.response.data as unknown))
    } else {
      yield put(availableRoomsError(error))
      throw error
    }
  }
}
