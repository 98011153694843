import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { UserDetail, UserType } from '../../../types'
import {
  usersRequest,
  usersSuccess,
  usersError,
  userCreateRequest,
  userCreateSuccess,
  userCreateError,
  userDetailRequest,
  userDetailSuccess,
  userDetailError,
  userUpdateRequest,
  userUpdateSuccess,
  userUpdateError,
  userDeleteRequest,
  userDeleteSuccess,
  userDeleteError,
  usersCleanErrorRequest,
  usersCleanSuccessRequest,
} from '../actions/users/actions'

interface UsersState {
  users: UserType[]
  user: UserDetail
  isLoading: boolean
  success: string
  error: string
}

export const initialState = (): UsersState => ({
  users: [],
  user: null,
  isLoading: false,
  success: '',
  error: '',
})

export const usersReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(usersRequest, (state) => {
      state.isLoading = true
    })
    .addCase(usersSuccess, (state, { payload }: PayloadAction<UserType[]>) => {
      state.users = payload
      state.isLoading = false
    })
    .addCase(usersError, (state, { payload }) => {
      console.error('usersReducer - usersGetterError', payload)
      state.error = payload.message
      state.users = initialState().users
      state.isLoading = false
    })
    .addCase(userCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(userCreateSuccess, (state) => {
      state.isLoading = false
      state.success = 'add_success'
    })
    .addCase(userCreateError, (state, { payload }) => {
      console.error('usersReducer - userCreateError', payload)
      state.isLoading = false
      state.error = payload
    })
    .addCase(userDetailRequest, (state) => {
      state.user = initialState().user
      state.isLoading = true
    })
    .addCase(userDetailSuccess, (state, { payload }: PayloadAction<UserDetail>) => {
      state.user = payload
      state.isLoading = false
    })
    .addCase(userDetailError, (state, { payload }) => {
      console.error('usersReducer - userDetailError', payload)
      state.error = payload.message
      state.user = initialState().user
      state.isLoading = false
    })
    .addCase(userUpdateRequest, (state) => {
      state.users = initialState().users
      state.isLoading = true
    })
    .addCase(userUpdateSuccess, (state) => {
      state.isLoading = false
      state.success = 'update_success'
    })
    .addCase(userUpdateError, (state, { payload }) => {
      console.error('usersReducer - userUpdateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(userDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(userDeleteSuccess, (state, { payload }: PayloadAction<number>) => {
      state.users = state.users.filter((user) => user.id !== Number(payload))
      state.success = 'delete_success'
      state.isLoading = false
    })
    .addCase(userDeleteError, (state, { payload }) => {
      console.error('usersReducer - userDeleteError', payload)
      state.error = payload.message
      state.users = initialState().users
      state.isLoading = false
    })
    .addCase(usersCleanErrorRequest, (state) => {
      state.error = ''
    })
    .addCase(usersCleanSuccessRequest, (state) => {
      state.success = ''
    })
})
