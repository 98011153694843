import { Box } from '@mui/material'
import axios from 'axios'
import { useSSE } from 'react-hooks-sse'

import { DeviceEvent } from '../../types'
import ButtonPanel from './ButtonPanel'

const AllStopButton = () => {
  const allDevices = useSSE<DeviceEvent[]>('devices', [])
  const devices = allDevices.filter((device) => device.type !== 'rcu')
  const inStop = !!devices.find((device) => device.state === 'READY')
  const color = inStop ? 'success' : 'primary'
  const iconSrc = inStop ? '/stop.png' : '/all.png'

  const onClickHandle = () => {
    if (inStop) {
      axios.post(`/v0/api/actions/all/stop/2`)
    } else {
      axios.post(`/v0/api/actions/all/listen/2`)
    }
  }

  return (
    <ButtonPanel variant="contained" widthAndHeight={72} color={color} onClick={onClickHandle}>
      <Box p={3}>
        <Box
          display="flex"
          component="img"
          src={iconSrc}
          sx={{ objectFit: 'contain' }}
          width="100%"
          height="100%"
        />
      </Box>
    </ButtonPanel>
  )
}

export default AllStopButton
