import { useState } from 'react'
import { Checkbox, Grid, FormControlLabel, FormGroup, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IpDevice, Profile, Tasks } from '../types'
import { Room } from '../hip/types'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../common/store/selectors/user'

interface IProps {
  items: (Tasks | IpDevice | Room | Profile)[]
  selectedItems: number[]
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>
}

const fixedProfiles = [2, 3, 4, 5, 6, 7, 8]

const CheckboxSelector = ({ items, selectedItems, setSelectedItems }: IProps) => {
  const { product_name } = useSelector(getUserInfo)
  const isHIP = product_name === 'HELP_IP'
  const { t } = useTranslation()
  const [selectAll, setSelectAll] = useState(false)
  const isSelected = (itemId: number) => selectedItems.includes(itemId)

  const isProfile = (item: Tasks | IpDevice | Room | Profile): item is Profile => {
    return (item as Profile).editable !== undefined
  }

  const isIpDevice = (item: Tasks | IpDevice | Room | Profile): item is IpDevice => {
    return (item as IpDevice).description !== undefined
  }

  const toggleSelection = (itemId: number, item: Tasks | IpDevice | Room | Profile) => {
    if (isHIP && isProfile(item) && fixedProfiles.includes(itemId)) {
      // Si es HIP y el item está en fixedProfiles no permite des-seleccionarlo
      if (!isSelected(itemId)) {
        setSelectedItems([...selectedItems, itemId])
      }
    } else {
      const updatedSelected = isSelected(itemId)
        ? selectedItems.filter((item) => item !== itemId)
        : [...selectedItems, itemId]
      setSelectedItems(updatedSelected)
    }
  }

  const handleSelectAll = () => {
    const updatedSelected = selectAll ? [] : items.map((item) => item.id)
    setSelectedItems(updatedSelected)
    setSelectAll(!selectAll)
  }

  const CheckedItems = () => {
    return (
      <Paper sx={{ width: 200, height: 230, overflow: 'auto', padding: '25px' }}>
        <FormGroup>
          {items.map((item) =>
            isSelected(item.id) ? (
              <FormControlLabel
                data-e2e="checkbox-selected-item"
                key={item.id}
                control={
                  <Checkbox
                    data-e2e={`checkbox-selected-item-${item.id}`}
                    checked={true}
                    onChange={() => toggleSelection(item.id, item)}
                    disabled={isHIP && isProfile(item) && fixedProfiles.includes(item.id)}
                  />
                }
                label={isIpDevice(item) ? item.description : item.name}
              />
            ) : null,
          )}
        </FormGroup>
      </Paper>
    )
  }

  const UncheckedItems = () => {
    return (
      <Paper sx={{ width: 200, height: 230, overflow: 'auto', padding: '25px' }}>
        <FormGroup>
          {items.map((item) =>
            !isSelected(item.id) ? (
              <FormControlLabel
                data-e2e="checkbox-item"
                key={item.id}
                control={
                  <Checkbox
                    data-e2e={`checkbox-item-${item.id}`}
                    checked={false}
                    onChange={() => toggleSelection(item.id, item)}
                  />
                }
                label={isIpDevice(item) ? item.description : item.name}
              />
            ) : null,
          )}
        </FormGroup>
      </Paper>
    )
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
              label={t('select_all')}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={3}>
          <Typography align="left" variant="subtitle1">
            {t('selected')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <UncheckedItems />
        </Grid>
        <Grid item xs={3}>
          <CheckedItems />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CheckboxSelector
