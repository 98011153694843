export const enum Privileges {
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  VIEW_DEVICE = 'VIEW_DEVICE',
  ADD_DEVICE = 'ADD_DEVICE',
  EDIT_DEVICE = 'EDIT_DEVICE',
  DELETE_DEVICE = 'DELETE_DEVICE',
  VIEW_PROFILES = 'VIEW_PROFILES',
  ADD_PROFILES = 'ADD_PROFILES',
  EDIT_PROFILES = 'EDIT_PROFILES',
  DELETE_PROFILES = 'DELETE_PROFILES',
  VIEW_USERS = 'VIEW_USERS',
  ADD_USERS = 'ADD_USERS',
  EDIT_USERS = 'EDIT_USERS',
  DELETE_USERS = 'DELETE_USERS',
  VIEW_ZONES = 'VIEW_ZONES',
  ADD_ZONES = 'ADD_ZONES',
  EDIT_ZONES = 'EDIT_ZONES',
  DELETE_ZONES = 'DELETE_ZONES',
  VIEW_LICENSE = 'VIEW_LICENSE',
  ADD_LICENSE = 'ADD_LICENSE',
  EDIT_LICENSE = 'EDIT_LICENSE',
  DELETE_LICENSE = 'DELETE_LICENSE',
  VIEW_RADIOS = 'VIEW_RADIOS',
  ADD_RADIOS = 'ADD_RADIOS',
  EDIT_RADIOS = 'EDIT_RADIOS',
  DELETE_RADIOS = 'DELETE_RADIOS',
  VIEW_MUSIC_FILES = 'VIEW_MUSIC_FILES',
  ADD_MUSIC_FILES = 'ADD_MUSIC_FILES',
  EDIT_MUSIC_FILES = 'EDIT_MUSIC_FILES',
  DELETE_MUSIC_FILES = 'DELETE_MUSIC_FILES',
  VIEW_MESSAGE_FILES = 'VIEW_MESSAGE_FILES',
  ADD_MESSAGE_FILES = 'ADD_MESSAGE_FILES',
  EDIT_MESSAGE_FILES = 'EDIT_MESSAGE_FILES',
  DELETE_MESSAGE_FILES = 'DELETE_MESSAGE_FILES',
  VIEW_ROOMS = 'VIEW_ROOMS',
  ADD_ROOMS = 'ADD_ROOMS',
  EDIT_ROOMS = 'EDIT_ROOMS',
  DELETE_ROOMS = 'DELETE_ROOMS',
  VIEW_HIP_PROFILES = 'VIEW_HIP_PROFILES',
  ADD_HIP_PROFILES = 'ADD_HIP_PROFILES',
  EDIT_HIP_PROFILES = 'EDIT_HIP_PROFILES',
  DELETE_HIP_PROFILES = 'DELETE_HIP_PROFILES',
  VIEW_KEYS = 'VIEW_KEYS',
  ADD_KEYS = 'ADD_KEYS',
  EDIT_KEYS = 'EDIT_KEYS',
  DELETE_KEYS = 'DELETE_KEYS',
  ALL = 'ALL',
  VIEW_TASKS = 'VIEW_TASKS',
  ADD_TASKS = 'ADD_TASKS',
  EDIT_TASK = 'EDIT_TASK',
  DELETE_TASK = 'DELETE_TASK',
  VIEW_SETTINGS = 'VIEW_SETTINGS',
  ADD_SETTINGS = 'ADD_SETTINGS',
  DELETE_SETTINGS = 'DELETE_SETTINGS',
  EDIT_SETTINGS = 'EDIT_SETTINGS',
  VIEW_APP_LOGS = 'VIEW_APP_LOGS',
  ADD_APP_LOGS = 'ADD_APP_LOGS',
  DELETE_APP_LOGS = 'DELETE_APP_LOGS',
  EDIT_APP_LOGS = 'EDIT_APP_LOGS',
}
