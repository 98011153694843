import { Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DetailItem from './DetailItem'

interface IProps {
  title: string
  object: [string, string | number][]
  'data-e2e'?: string
}

const DetailContainer: React.FC<IProps> = ({ title, object, 'data-e2e': dataE2E }: IProps) => {
  const { t } = useTranslation()

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Grid item textAlign="center">
        <Typography variant="h6" mb={3}>
          {t(title)}
        </Typography>
      </Grid>
      <Grid container spacing={2} textAlign="center">
        {object.map(
          ([key, value]) =>
            value !== undefined &&
            value !== null && <DetailItem key={key} label={key} value={value} data-e2e={dataE2E} />,
        )}
      </Grid>
    </Paper>
  )
}

export default DetailContainer
