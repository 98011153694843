import { Box, Modal, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'

const IsLoadingModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'white',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
          padding="20px"
        >
          <CircularProgress size={90} style={{ marginBottom: '20px' }} />
          <Typography variant="h6" color="text.secondary" align="center">
            {t('changing_database')}
          </Typography>
        </Box>
      </div>
    </Modal>
  )
}

export default IsLoadingModal
