import { Box, Theme, Typography } from '@mui/material'

const styles = {
  zonePaper: (theme: Theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    borderRadius: '5%',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: '5px',
    maxWidth: '100%',
  }),
}

interface IProps {
  zones: string[]
}

const ZoneIndicator = ({ zones }: IProps) => {
  return (
    <Box sx={styles.zonePaper}>
      {zones.length > 0 ? (
        zones.map((zone, index) => (
          <Typography key={index} variant="h6" lineHeight={2}>
            {zone}
          </Typography>
        ))
      ) : (
        <Typography variant="h6" align="center">
          No specific zone assigned
        </Typography>
      )}
    </Box>
  )
}

export default ZoneIndicator
