import { createAction } from '@reduxjs/toolkit'
import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_ERROR,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_ERROR,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USERS_CLEAN_ERROR_REQUEST,
  USERS_CLEAN_SUCCESS_REQUEST,
} from './actionTypes'
import { UserType, UserDetail, NewUserData } from '../../../../types'

export const usersRequest = createAction<number[]>(USERS_REQUEST)
export const usersSuccess = createAction<UserType[]>(USERS_SUCCESS)
export const usersError = createAction<any>(USERS_ERROR)

export const userDetailRequest = createAction<string>(USER_DETAIL_REQUEST)
export const userDetailSuccess = createAction<UserDetail>(USER_DETAIL_SUCCESS)
export const userDetailError = createAction<any>(USER_DETAIL_ERROR)

export const userCreateRequest = createAction<NewUserData>(USER_CREATE_REQUEST)
export const userCreateSuccess = createAction<UserType>(USER_CREATE_SUCCESS)
export const userCreateError = createAction<any>(USER_CREATE_ERROR)

export const userUpdateRequest = createAction<NewUserData>(USER_UPDATE_REQUEST)
export const userUpdateSuccess = createAction<UserType>(USER_UPDATE_SUCCESS)
export const userUpdateError = createAction<any>(USER_UPDATE_ERROR)

export const userDeleteRequest = createAction<number>(USER_DELETE_REQUEST)
export const userDeleteSuccess = createAction<number>(USER_DELETE_SUCCESS)
export const userDeleteError = createAction<any>(USER_DELETE_ERROR)

export const usersCleanErrorRequest = createAction(USERS_CLEAN_ERROR_REQUEST)
export const usersCleanSuccessRequest = createAction(USERS_CLEAN_SUCCESS_REQUEST)
