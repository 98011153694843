import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { Acces_key, deletedItem, User_Key } from '../../types'
import { UserDetail } from '../../../types'
import {
  keyCreateError,
  keyCreateRequest,
  keyCreateSuccess,
  keyUpdateError,
  keyUpdateRequest,
  keyUpdateSuccess,
  hipUserCleanErrorRequest,
  hip_usersKeysRequest,
  hip_usersKeysSuccess,
  hip_usersKeysError,
  hip_userCreateRequest,
  hip_userCreateSuccess,
  hip_userCreateError,
  hip_userUpdateRequest,
  hip_userUpdateSuccess,
  hip_userUpdateError,
  hip_userDeleteRequest,
  hip_userDeleteSuccess,
  hip_userDeleteError,
  keyDeleteError,
  keyDeleteSuccess,
  keyDeleteRequest,
  hip_userDetailRequest,
  hip_userDetailSuccess,
  hip_userDetailError,
  hipUserCleanSuccessRequest,
} from '../../actions/hipUsers/actions'

interface NursesState {
  hipUsers: User_Key[]
  hipUser: UserDetail
  isLoading: boolean
  error: string
  success: string
}

export const initialState = (): NursesState => ({
  hipUsers: [],
  hipUser: {
    id: null,
    username: '',
    password: '',
    name: '',
    active: null,
    user_id: null,
    access_key: null,
    mqtt_topic: '',
  },
  isLoading: false,
  error: '',
  success: '',
})

export const hipUsersReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(hip_userCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(hip_userCreateSuccess, (state, { payload }: PayloadAction<UserDetail>) => {
      const { user_id, username, name, active, access_key } = payload
      const hipUser: User_Key = {
        id: user_id,
        username,
        name,
        active,
        access_key: access_key.id ? access_key : null,
      }
      state.hipUsers.unshift(hipUser)
      state.isLoading = false
      state.success = 'add_success'
    })
    .addCase(hip_userCreateError, (state, { payload }) => {
      console.error('hipUserssReducer - hip_userCreateError', payload.message)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(hip_userUpdateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(hip_userUpdateSuccess, (state, { payload }: PayloadAction<User_Key>) => {
      const { id, username, name, active, access_key } = payload
      const updatedNurse: UserDetail = {
        id,
        username,
        password: state.hipUser.password,
        name,
        active,
        access_key: access_key.id ? access_key : null,
        mqtt_topic: state.hipUser.mqtt_topic,
      }
      state.hipUser = updatedNurse
      state.hipUsers = state.hipUsers.map((hipUser) => (hipUser.id === id ? payload : hipUser))
      state.isLoading = false
    })
    .addCase(hip_userUpdateError, (state, { payload }) => {
      console.error('hipUserssReducer - hip_userUpdateError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(hip_userDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(hip_userDeleteSuccess, (state, { payload }: PayloadAction<number>) => {
      state.hipUsers = state.hipUsers.filter((hipUser) => hipUser.id !== payload)
      state.isLoading = false
    })
    .addCase(hip_userDeleteError, (state, { payload }) => {
      console.error('hipUserssReducer - hip_userDeleteError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(hip_usersKeysRequest, (state) => {
      state.hipUsers = []
      state.isLoading = true
    })
    .addCase(hip_usersKeysSuccess, (state, { payload }: PayloadAction<User_Key[]>) => {
      state.hipUsers = payload
      state.isLoading = false
    })
    .addCase(hip_usersKeysError, (state, { payload }) => {
      console.error('hipUserssReducer - hip_usersKeysError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(hip_userDetailRequest, (state) => {
      state.hipUser = initialState().hipUser
      state.isLoading = true
    })
    .addCase(hip_userDetailSuccess, (state, { payload }: PayloadAction<UserDetail>) => {
      state.hipUser = payload
      state.isLoading = false
    })
    .addCase(hip_userDetailError, (state, { payload }) => {
      console.error('hipUserssReducer - hip_userDetailError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(keyCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(keyCreateSuccess, (state, { payload }: PayloadAction<Acces_key>) => {
      const hip_user = payload.user
      const hipUserUpdated = {
        id: hip_user.id,
        username: hip_user.username,
        name: hip_user.name,
        active: true,
        access_key: { id: payload.id, key: payload.key, enabled: payload.enabled },
      }
      state.hipUsers = state.hipUsers.map((hipUser) =>
        hipUser.id === hip_user.id ? hipUserUpdated : hipUser,
      )
      state.success = 'add_success'
      state.isLoading = false
    })
    .addCase(keyCreateError, (state, { payload }) => {
      console.error('hipUserssReducer - keyCreateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(keyUpdateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(keyUpdateSuccess, (state, { payload }: PayloadAction<Acces_key>) => {
      const hip_user = payload.user
      const hipUserUpdated = {
        id: hip_user.id,
        username: hip_user.username,
        name: hip_user.name,
        active: true,
        access_key: { id: payload.id, key: payload.key, enabled: payload.enabled },
      }
      state.hipUsers = state.hipUsers.map((hipUser) =>
        hipUser.id === hip_user.id ? hipUserUpdated : hipUser,
      )
      state.isLoading = false
    })
    .addCase(keyUpdateError, (state, { payload }) => {
      console.error('hipUserssReducer - keyUpdateError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(keyDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(keyDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
      const deletedKey = Number(payload.id)
      const hipUser = state.hipUsers.find(
        (hipUser) => hipUser.access_key && hipUser.access_key.id === deletedKey,
      )
      const hipUserUpdated = {
        id: hipUser.id,
        username: hipUser.username,
        name: hipUser.name,
        active: hipUser.active,
      }
      state.hipUsers = state.hipUsers.map((hipUser) =>
        hipUser.id === hipUserUpdated.id ? hipUserUpdated : hipUser,
      )
      state.isLoading = false
    })
    .addCase(keyDeleteError, (state, { payload }) => {
      console.error('hipUserssReducer - keyDeleteError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(hipUserCleanErrorRequest, (state) => {
      state.success = ''
    })
    .addCase(hipUserCleanSuccessRequest, (state) => {
      state.error = ''
    })
})
