import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { userDeleteError, userDeleteSuccess } from '../../actions/users/actions'
import { userDelete } from '../../../../hip/api/userDelete'

export function* sagaUserDelete({ payload }: Action<number>) {
  try {
    const response: number = yield call(userDelete, payload)
    yield put(userDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(userDeleteError(error.response.data as unknown))
    } else {
      yield put(userDeleteError(error))
      throw error
    }
  }
}
