import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from './root'
import { LoginInfoResponse, UserLogin } from '../../api/types'
import { loginError, loginSuccess, userInfoRequest } from '../actions/user/actions'
import { postLogin } from '../../api/login/login'
import { userProfileRequest } from '../actions/profile/actions'
import { licenseRequest } from '../actions/license/action'

export function* loginSaga({ payload }: Action<UserLogin>) {
  try {
    const response: LoginInfoResponse = yield call(postLogin, payload)
    yield put(loginSuccess(response))
    yield put(userProfileRequest({ user_id: response.id.toString() }))
    yield put(userInfoRequest({ username: 'me' }))
    yield put(licenseRequest())
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 500) {
        console.log('Server Error:', error)
      } else {
        yield put(loginError(error.response.data as unknown))
      }
    } else {
      yield put(loginError(error))
      console.log('Error2', error)
      throw error
    }
  }
}
