import axios from 'axios'

export const getHipUsersByProfiles = async (ids: number[]) => {
  let profiles = ''
  if (ids) {
    ids.map((id) => {
      profiles = profiles.concat(`profile_id=${id}&`)
    })
  }
  const response = await axios.get(`/v0/api/profile/users?${profiles}`)
  return response.data
}
