import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Cancel, Done, PersonAdd } from '@mui/icons-material'
import { Button, Grid, TextField, Box, CircularProgress } from '@mui/material'
import { CommonContent } from '../components/CommonContent'
import CheckboxSelector from '../components/CheckboxSelector'
import { Profile, NewUser, NewUserData } from '../types'
import { usersState } from '../common/store/selectors/users'
import {
  userCreateRequest,
  userDetailRequest,
  userUpdateRequest,
} from '../common/store/actions/users/actions'
import { topicGenerator, multicast_acrt, ipMulticast } from '../Utils'

import axios from 'axios'
import { IUserProfile } from '../common/api/types'
import { getUserInfo } from '../common/store/selectors/user'

export type UserFormValues = {
  username: string
  password: string
  name: string
  multicast_ip?: string
  multicast_port?: number
  mqtt_topic: string
}

export const UserForm = () => {
  const { product_name } = useSelector(getUserInfo)
  const { id } = useParams()
  const isEdit = id !== 'add'
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const { profiles } = useSelector() //falta un profile state
  const [profiles, setProfiles] = useState<Profile[]>()
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const { control, handleSubmit, reset, getValues } = useForm<UserFormValues>()
  const { user, isLoading } = useSelector(usersState)

  useEffect(() => {
    // if (!profiles.length) {
    //   dispatch(profilesRequest())
    // }
    const fetchData = async () => {
      try {
        const prof: any = await axios.get<Profile[]>(`/v0/api/profile`)
        let profiles = prof.data
        if (product_name === 'PAGING') {
          //No se muestran perfiles HIP
          profiles = profiles.filter(
            (profile: Profile) => ![2, 3, 4, 5, 6, 7, 8].includes(profile.id),
          )
        }
        if (product_name === 'HELP_IP') {
          if (!isEdit) {
            profiles = profiles.filter(
              (profile: Profile) => ![2, 4, 5, 6, 7, 8].includes(profile.id),
            )
          }
        }
        setProfiles(profiles)
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
    if (isEdit) {
      dispatch(userDetailRequest(id))
      const fetchData = async () => {
        try {
          const prof = await axios.get<IUserProfile>(`/v0/api/user_profile/${id}`)
          const user_profiles = prof.data.profiles.map((profile) => profile.profile_id)
          setSelectedItems(user_profiles)
        } catch (e) {
          console.log(e)
        }
      }
      fetchData()
    }
  }, [id])

  useEffect(() => {
    if (isEdit && user) {
      reset({
        username: user.username || '',
        password: user.password || '',
        name: user.name || '',
        multicast_ip: user.multicast_ip || '',
        multicast_port: user.multicast_port || null,
        mqtt_topic: user.mqtt_topic || '',
      })
    }
  }, [isLoading])

  const onSubmit = handleSubmit((data: UserFormValues) => {
    if (data) {
      const { username, password, name, mqtt_topic, multicast_ip, multicast_port } = data
      const newUser: NewUser = {
        username,
        password,
        name,
        mqtt_topic,
        multicast_ip,
        multicast_port,
        active: true,
      }
      const newData: NewUserData = {
        data: newUser,
        profiles: selectedItems,
      }
      if (isEdit) {
        newData.id = id
        dispatch(userUpdateRequest(newData))
      } else {
        dispatch(userCreateRequest(newData))
      }
      navigate('/users')
      reset({
        username: '',
        password: '',
        name: '',
      })
      setSelectedItems([])
    }
  })

  return (
    <CommonContent
      title={isEdit ? t('edit_user') : t('add_user')}
      titleIcon={<PersonAdd style={{ color: 'white' }} />}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <form autoComplete="off" autoCorrect="on">
          <Controller
            name="username"
            control={control}
            defaultValue={isEdit ? user?.username : ''}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
              validate: () => {
                const username = getValues('username')
                if (username.trim() !== username) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
                data-e2e="users-fld-username"
                label={isEdit ? t('update_username') : t('username')}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : null}
                error={!!error}
                style={{ paddingBottom: 20 }}
                size="medium"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <Controller
            name={'password'}
            control={control}
            defaultValue={''}
            rules={{
              // required: {
              //   value: true,
              //   message: t('required'),
              // },
              validate: () => {
                const name = getValues('password')
                if (name.trim() !== name) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="password-fld"
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                label={isEdit ? t('update_password') : t('password')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            defaultValue={isEdit ? user?.name : ''}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="users-fld-name"
                label={isEdit ? t('update_name') : t('name')}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : null}
                error={!!error}
                style={{ paddingBottom: 20 }}
                fullWidth
                size="small"
                variant="outlined"
              />
            )}
          />
          <Controller
            name="multicast_ip"
            control={control}
            defaultValue={isEdit ? user?.multicast_ip : `224.0.0.${ipMulticast}`}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="users-fld-multicast_ip"
                label={isEdit ? t('update_multicast_ip') : t('multicast_ip')}
                value={value}
                onChange={onChange}
                style={{ paddingBottom: 20 }}
                fullWidth
                placeholder="224.0.0.10"
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                variant="outlined"
              />
            )}
          />
          <Controller
            name="multicast_port"
            control={control}
            defaultValue={isEdit ? user?.multicast_port : multicast_acrt}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="users-fld-multicast_port"
                label={isEdit ? t('update_multicast_port') : t('multicast_port')}
                value={value}
                onChange={onChange}
                style={{ paddingBottom: 20 }}
                fullWidth
                placeholder="52100"
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                variant="outlined"
              />
            )}
          />
          <Controller
            name="mqtt_topic"
            control={control}
            defaultValue={isEdit ? user?.mqtt_topic : topicGenerator()}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="users-fld-mqtt_topic"
                label={isEdit ? t('update_mqtt_topic') : t('mqtt_topic')}
                value={value}
                onChange={onChange}
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                variant="outlined"
              />
            )}
          />
          {profiles && (
            <Box mt={4} border={'1px solid lightgrey'} borderRadius="5px" m={4} p={5}>
              <CheckboxSelector
                items={profiles}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
              />
            </Box>
          )}
          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                onClick={() => {
                  navigate('/users')
                }}
                variant="outlined"
                color="secondary"
                startIcon={<Cancel />}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedItems.length}
                onClick={onSubmit}
                startIcon={<Done />}
                data-e2e="users-btn-add"
              >
                {isEdit ? t('save') : t('add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </CommonContent>
  )
}
