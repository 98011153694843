import axios from 'axios'
//
import { ILicenseRequest } from '../types'

export const getLicense = async () => {
  const response = await axios.get(`/v0/api/license`)
  return response.data
}

export const updateLicense = async ({ license }: ILicenseRequest) => {
  const response = await axios.post(`/v0/api/license`, { license: license })
  return response.data
}
