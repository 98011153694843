import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { UserType, NewUserData } from '../../../../types'
import { userUpdate } from '../../../../hip/api/userUpdate'
import { addProfile } from '../../../../hip/api/userAddProfile'
import { userUpdateSuccess, userUpdateError } from '../../actions/users/actions'

export function* sagaUserUpdate({ payload }: Action<NewUserData>) {
  try {
    const response: UserType = yield call(userUpdate, payload)
    const { profiles } = payload
    if (profiles.length) {
      const profileData = {
        user_id: response.id,
        profiles: profiles,
      }
      yield call(addProfile, profileData)
    }
    yield put(userUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(userUpdateError(error.response.data as unknown))
    } else {
      yield put(userUpdateError(error))
      throw error
    }
  }
}
