import { Container, Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Routes, Route } from 'react-router-dom'
import { Devices } from './devices/Devices'
import Users from './users/userManager'
import { UserForm } from './users/userForm'
import DeviceForm from './devices/DeviceForm'
import { useTranslation } from 'react-i18next'
import { Profiles } from './profiles/profilesManager'
import FileManager from './file_manager/FileManager'
import { RadiosManager } from './radio_manager/RadioManager'
import { RadiosForm } from './radio_manager/RadiosForm'
import { ZoneManager } from './zones/ZoneManager'
import { ZoneForm } from './zones/ZoneForm'
import AppHeader from './AppHeader'
import License from './license/license'
import { StatusPage } from './status/StatusPage'
import ProfilesForm2 from './profiles/ProfilesForm2'
import HelpIPRoutes from './hip/HelpIPRoutes'
import Tasks from './tasks/Tasks'
import TasksView from './tasks/TaskView'
import ExceptionsForm from './tasks/ExceptionsForm'
import TaskForm from './tasks/TaskForm'
import SettingsForm from './settings/SettingsForm'
import SettingsPage from './settings/Settings'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

const types = ['rule_tasks', 'audio_tasks', 'exception_rules']

export default function Admin() {
  const { classes } = useStyles()
  return (
    <AppHeader>
      <Container maxWidth="lg" className={classes.container}>
        <div className="admin">
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="users" element={<Users />} />
            <Route path="users/:id" element={<UserForm />} />
            <Route path="profiles" element={<Profiles />} />
            <Route path="profiles/:id" element={<ProfilesForm2 />} />
            <Route path="devices" element={<Devices />} />
            <Route path="devices/:mac" element={<DeviceForm />} />
            <Route path="msg-files" element={<FileManager type="msg-files" />} />
            <Route path="music-files" element={<FileManager type="music-files" />} />
            <Route path="radios" element={<RadiosManager />} />
            <Route path="radios/:id" element={<RadiosForm />} />
            <Route path="groups" element={<ZoneManager />} />
            <Route path="groups/:id" element={<ZoneForm />} />
            <Route path="license" element={<License />} />
            <Route path="tasks" element={<Tasks types={types} />} />
            <Route path="tasks/:type" element={<TasksView />} />
            <Route path="tasks/:type/:taskId" element={<TaskForm />} />
            <Route path="tasks/add_exceptions" element={<ExceptionsForm />} />
            <Route path="zone_settings" element={<SettingsPage scope="zone" />} />
            <Route path="general_settings" element={<SettingsPage scope="general" />} />
            <Route path="settings/add_zone_settings" element={<SettingsForm scope="zone" />} />
            <Route
              path="settings/add_general_settings"
              element={<SettingsForm scope="general" />}
            />
            <Route path="settings/:id" element={<SettingsForm />} />
            {HelpIPRoutes}
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </div>
      </Container>
    </AppHeader>
  )
}

export const Index = () => {
  return <StatusPage />
}

export const NotFound = () => {
  const { t } = useTranslation()
  return <h2>{t('not_found')}</h2>
}
