/* eslint @typescript-eslint/no-var-requires: "off" */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Delete, Edit, SpeakerGroup } from '@mui/icons-material'
import { CommonContent } from '../components/CommonContent'
import {
  groupDeleteRequest,
  groupsCleanErrorRequest,
  groupsCleanSuccessRequest,
  groupsRequest,
  groupCleanRequest,
} from '../common/store/actions/groups/actions'
import { groupsStates } from '../common/store/selectors/groups'
import { Group } from '../common/api/types'
import { getUserInfo } from '../common/store/selectors/user'

export const ZoneManager = () => {
  const { hasPrivilege } = usePrivilege()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { groups, success, error } = useSelector(groupsStates)
  const [open, setOpen] = useState(false)
  const [groupToDelete, setGroupToDelete] = useState<number>(null)
  const { paging_mode } = useSelector(getUserInfo)
  const isMulticast = paging_mode == 'MULTICAST'

  useEffect(() => {
    dispatch(groupsRequest())
  }, [dispatch])

  useEffect(() => {
    if (groups.length > 0) {
      console.log('rendering', groups)
    }
  }, [groups])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(groupsCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(groupsCleanErrorRequest())
    }
  }, [success, error])

  const handleClickAdd = () => {
    dispatch(groupCleanRequest())
    navigate('/groups/add')
  }

  const handleClickEdit = (id: number) => {
    dispatch(groupCleanRequest())
    navigate('/groups/' + id)
  }

  const handleClick = (group: Group) => {
    setGroupToDelete(group.id)
    setOpen(true)
  }

  const handleConfirmDelete = () => {
    dispatch(groupDeleteRequest(groupToDelete))
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <CommonContent
      title={t('zones')}
      titleIcon={<SpeakerGroup style={{ color: 'white' }} />}
      data-e2e="zones-title"
    >
      {hasPrivilege(Privileges.ADD_ZONES) && (
        <Box mb={4}>
          <Button
            data-e2e="zones-btn-add"
            onClick={handleClickAdd}
            variant="contained"
            color="success"
            startIcon={<SpeakerGroup />}
          >
            {t('zones_add')}
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Divider />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center"> {t('name')}</TableCell>
              <TableCell align="center">{t('desc')}</TableCell>
              {isMulticast && (
                <>
                  <TableCell align="center">{t('ipMulticast')}</TableCell>
                  <TableCell align="center">{t('portMulticast')}</TableCell>
                </>
              )}
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              {!isMulticast && (
                <>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.id} data-e2e={'zones-row-' + String(group.id)}>
                <TableCell
                  component="th"
                  scope="group"
                  align="center"
                  data-e2e={'group-row-cell-id'}
                >
                  {group.id}
                </TableCell>
                <TableCell align="center" component="th" scope="row" data-e2e="zones-row-cell-name">
                  {group.name}
                </TableCell>
                <TableCell align="center" data-e2e="zones-row-cell-description">
                  {group.description}
                </TableCell>
                <TableCell align="center" data-e2e="zones-row-cell-ipMulticast">
                  {group.isMusic ? group.ipMulticast : ''}
                </TableCell>
                <TableCell align="center">{group.isMusic ? group.portMulticast : ''}</TableCell>
                <TableCell align="center" data-e2e="zones-row-cell-actions">
                  <Grid>
                    {hasPrivilege(Privileges.EDIT_ZONES) && (
                      <Button
                        onClick={() => handleClickEdit(group.id)}
                        data-e2e="zones-row-cell-actions-edit"
                      >
                        <Edit color="primary" />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid>
                    {group.name !== 'messages' && (
                      <>
                        {hasPrivilege(Privileges.DELETE_ZONES) && (
                          <Button
                            variant="text"
                            onClick={() => handleClick(group)}
                            data-e2e="zones-row-cell-actions-delete"
                          >
                            <Delete color="error" />
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e="zones-dlg-delete"
      >
        <DialogTitle id="responsive-dialog-title">{t('zones_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('zones_delete_ask')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDelete()}
            variant="outlined"
            color="secondary"
            data-e2e="zones-dlg-delete-btn-delete"
          >
            {t('delete')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
            data-e2e="zones-dlg-delete-btn-cancel"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </CommonContent>
  )
}
