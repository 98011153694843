import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../root'
import { settingsError, settingsSuccess } from '../../actions/settings/actions'
import { PaginatedResponse } from '../../../../types'
import { getSettings } from '../../../api/settings/settingsGetter'
import { PageSet } from '../../../../hip/types'

export function* sagaSettingsGetter({ payload }: Action<PageSet>) {
  try {
    const response: PaginatedResponse = yield call(getSettings, payload)
    yield put(settingsSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(settingsError(error.response.data as unknown))
    } else {
      yield put(settingsError(error))
      throw error
    }
  }
}
