import { useState } from 'react'
import { Box, Fab, Modal, Typography } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import HipButton from '../components/HipButton'
import { HipIconType } from '../types'

const styles = {
  floatingButton: {
    margin: 4,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '15px',
    width: '90%',
    maxWidth: '800px',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  isDesktop: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center',
  },
  isMobile: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '10px',
  },
}

const info = [
  { fill: '#354652', image: 'ERROR', name: 'Error' },
  { fill: '#979797', image: 'OK', name: 'End of service or stand by' },
  { fill: '#43aaf3', image: 'NURSE', name: 'Nurse at room' },
  { fill: '#df007c', image: 'CODE_BLUE', name: 'CODE BLUE ALARM' },
  { fill: '#5c2582', image: 'CODE_BLUE_NA', name: 'CODE BLUE UNATTENDED' },
  { fill: '#43aaf3', image: 'ROOM_READY', name: 'Room ready' },
  { fill: '#f9b03d', image: 'BED_CALL', name: 'Bed call' },
  { fill: '#5c2582', image: 'IGNORED', name: 'Bed call unattended' },
  { fill: '#f05a25', image: 'MESSAGE_SENT', name: 'Calling room or Call established' },
  { fill: '#43aaf3', image: 'ASSIGNED', name: 'Nurse assigned' },
  { fill: '#f9b03d', image: 'BATHROOM_CALL', name: 'Bathroom call' },
  { fill: '#5c2582', image: 'BATHROOM_IGNORED', name: 'Bathroom call unattended' },
  { fill: '#f05a25', image: 'BATH_MESSAGE_SENT', name: 'Bathroom call established' },
  { fill: '#f9b03d', image: 'CLEANING_REQ', name: 'Cleaning request' },
  { fill: '#43aaf3', image: 'CLEANING', name: 'Room under cleaning' },
  { fill: '#f9b03d', image: 'ROOM_SERVICE_REQ', name: 'Room service request' },
  { fill: '#43aaf3', image: 'ROOM_SERVICE', name: 'Room service' },
  { fill: '#43aaf3', image: 'MAINTENANCE', name: 'Room under maintenance' },
]

interface IProps {
  isDesktopMode: boolean
}

const IconsInfo = ({ isDesktopMode }: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Fab sx={styles.floatingButton}>
        <HelpIcon color="primary" onClick={handleOpenModal} />
      </Fab>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: 'absolute',
            top: isDesktopMode ? '80%' : '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '15px',
            width: isDesktopMode ? 'fit-content' : 'max-content',
            maxWidth: isDesktopMode ? '90%' : '80%',
          }}
        >
          <Box sx={isDesktopMode ? styles.isDesktop : styles.isMobile} data-e2e="room-list">
            {info.map((button, index) => (
              <div
                key={index}
                style={{
                  margin: '5px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <HipButton
                  // variant="contained"
                  viewMode="large-icon"
                  image={button.image as HipIconType}
                  fill={button.fill}
                  // color="warning"
                />
                <Typography variant="caption">{button.name} </Typography>
              </div>
            ))}
          </Box>
        </div>
      </Modal>
    </>
  )
}

export default IconsInfo
