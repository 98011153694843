import axios from 'axios'

import { VolumeUpdate } from '../../../types'

// VOLUME - TEMPORARY DESABLED - pending of hardware command implementation - DON'T DELETE
export const updateVolume = async (payload: { body: VolumeUpdate; id: number }) => {
  const { id, body } = payload
  const response = await axios.patch(`/v2/api/ip-devices/speaker/${id}`, body)
  return response.data
}
