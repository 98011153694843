import { createAction } from '@reduxjs/toolkit'
import {
  HIP_USERS_KEYS_REQUEST,
  HIP_USERS_KEYS_SUCCESS,
  HIP_USERS_KEYS_ERROR,
  HIP_USER_DETAIL_REQUEST,
  HIP_USER_DETAIL_SUCCESS,
  HIP_USER_DETAIL_ERROR,
  HIP_USER_CREATE_REQUEST,
  HIP_USER_CREATE_SUCCESS,
  HIP_USER_CREATE_ERROR,
  HIP_USER_UPDATE_REQUEST,
  HIP_USER_UPDATE_SUCCESS,
  HIP_USER_UPDATE_ERROR,
  HIP_USER_DELETE_REQUEST,
  HIP_USER_DELETE_SUCCESS,
  HIP_USER_DELETE_ERROR,
  KEY_CREATE_REQUEST,
  KEY_CREATE_SUCCESS,
  KEY_CREATE_ERROR,
  KEY_UPDATE_REQUEST,
  KEY_UPDATE_SUCCESS,
  KEY_UPDATE_ERROR,
  KEY_DELETE_REQUEST,
  KEY_DELETE_SUCCESS,
  KEY_DELETE_ERROR,
  HIP_USER_CLEAN_ERROR_REQUEST,
  HIP_USER_CLEAN_SUCCESS_REQUEST,
} from './actionTypes'
import { Acces_key, User_Key, KeyFormValues, deletedItem } from '../../types'
import { NewUserData, UserDetail } from '../../../types'

export const hip_usersKeysRequest = createAction<number[]>(HIP_USERS_KEYS_REQUEST)
export const hip_usersKeysSuccess = createAction<User_Key[]>(HIP_USERS_KEYS_SUCCESS)
export const hip_usersKeysError = createAction<any>(HIP_USERS_KEYS_ERROR)

export const hip_userDetailRequest = createAction<string>(HIP_USER_DETAIL_REQUEST)
export const hip_userDetailSuccess = createAction<UserDetail>(HIP_USER_DETAIL_SUCCESS)
export const hip_userDetailError = createAction<any>(HIP_USER_DETAIL_ERROR)

export const hip_userCreateRequest = createAction<NewUserData>(HIP_USER_CREATE_REQUEST)
export const hip_userCreateSuccess = createAction<UserDetail>(HIP_USER_CREATE_SUCCESS)
export const hip_userCreateError = createAction<any>(HIP_USER_CREATE_ERROR)

export const hip_userUpdateRequest = createAction<NewUserData>(HIP_USER_UPDATE_REQUEST)
export const hip_userUpdateSuccess = createAction<User_Key>(HIP_USER_UPDATE_SUCCESS)
export const hip_userUpdateError = createAction<any>(HIP_USER_UPDATE_ERROR)

export const hip_userDeleteRequest = createAction<number>(HIP_USER_DELETE_REQUEST)
export const hip_userDeleteSuccess = createAction<number>(HIP_USER_DELETE_SUCCESS)
export const hip_userDeleteError = createAction<any>(HIP_USER_DELETE_ERROR)

export const keyCreateRequest = createAction<KeyFormValues>(KEY_CREATE_REQUEST)
export const keyCreateSuccess = createAction<Acces_key>(KEY_CREATE_SUCCESS)
export const keyCreateError = createAction<any>(KEY_CREATE_ERROR)

export const keyUpdateRequest = createAction<{ updatedData: KeyFormValues; keyId: number }>(
  KEY_UPDATE_REQUEST,
)
export const keyUpdateSuccess = createAction<Acces_key>(KEY_UPDATE_SUCCESS)
export const keyUpdateError = createAction<any>(KEY_UPDATE_ERROR)

export const hipUserCleanErrorRequest = createAction(HIP_USER_CLEAN_ERROR_REQUEST)
export const hipUserCleanSuccessRequest = createAction(HIP_USER_CLEAN_SUCCESS_REQUEST)

export const keyDeleteRequest = createAction<number>(KEY_DELETE_REQUEST)
export const keyDeleteSuccess = createAction<deletedItem>(KEY_DELETE_SUCCESS)
export const keyDeleteError = createAction<any>(KEY_DELETE_ERROR)
