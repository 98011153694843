import { Box, Pagination } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

interface iProps {
  pages: number | null
  currentPage: number | null
  setSelectedPage: Dispatch<SetStateAction<string>>
}

const Paginate = ({ pages, currentPage, setSelectedPage }: iProps) => {
  const handleChange = (event: any) => {
    const { innerText } = event.target
    setSelectedPage(innerText)
  }

  return (
    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} mt={6} mb={3}>
      <Pagination
        hidePrevButton
        hideNextButton
        page={currentPage || 0}
        count={pages || 0}
        color="primary"
        variant="outlined"
        shape="rounded"
        onChange={(event) => handleChange(event)}
      />
    </Box>
  )
}

export default Paginate
