import {
  LocalHospital,
  Handyman,
  CleaningServices,
  CreditCard,
  RoomService,
  SupervisorAccount,
} from '@mui/icons-material'
// import { HipIcon } from '../components/HipIcon'
// case 'nurses': return <HipIcon viewMode="large-icon" image={'NURSE'} size={32} fill={'white'}/>

export const userIcon = (userType: string) => {
  switch (userType) {
    case 'nurses':
      return <SupervisorAccount style={{ color: 'white' }} />
    case 'maintenance':
      return <Handyman style={{ color: 'white' }} />
    case 'cleaning':
      return <CleaningServices style={{ color: 'white' }} />
    case 'roomReady':
      return <CreditCard style={{ color: 'white' }} />
    case 'roomService':
      return <RoomService style={{ color: 'white' }} />
    case 'admission':
      return <LocalHospital style={{ color: 'white' }} />
    default:
      break
  }
}
